// frontend/src/components/MessageList.js
import React from "react";

export default function MessageList({ messages = [], userChattingInfo, otherUserName, otherUser }) {
    // If messages isn't an array, convert it to an empty array
    const messageList = Array.isArray(messages) ? messages : [];
	// console.log("messages",messages)
    return (
        <div
            style={{
                border: "1px solid #ccc",
                padding: "10px",
                height: "300px",
                overflowY: "auto"
            }}
        >
            {messageList.map((msg) => {
                // console.log(msg);
                return (
                    <div key={msg._id} style={{ marginBottom: "10px" , whiteSpace: "pre-wrap" }}>
                        <strong>
                            {msg.sender === userChattingInfo._id ? `(You) ${userChattingInfo.first_name} ${userChattingInfo.last_name}` : otherUserName} :
                        </strong>
						{" "}
                        {msg.content}
                    </div>
                );
            })}
        </div>
    );
}
