import React, { useState } from "react";
import styled from "styled-components";
import { RadioButton, Success, LoadingStyle } from "../assets/styledcomponents/StyledComponents.js";

const SidebarListItem = ({ buildings, onBuildingSelect, selectedList }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the visibility of the buildings list
    const toggleList = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Container>
            <Button onClick={toggleList}>
                Lists {isOpen ? <>&nbsp;(See Less)</> : <>&nbsp;(See more)</>}
            </Button>
            {isOpen && (
                <>
                    {(!buildings || buildings.length === 0) ? (
                        <LoadingContainer>
                            <LoadingStyle />
                        </LoadingContainer>
                    ) : (
                        <List>
                            <ListItem
                                key="all_lists_together"
                                onClick={() => onBuildingSelect("all_lists_together")}
                                isSelected={selectedList === "all_lists_together"}
                            >
                                All Lists
                            </ListItem>
                            {buildings.map((building) => (
                                <ListItem
                                    key={building._id}
                                    onClick={() => onBuildingSelect(building.listcollection)}
                                    isSelected={selectedList === building.listcollection}
                                >
                                    {building.list_name}
                                </ListItem>
                            ))}
                        </List>
                    )}
                </>
            )}
        </Container>
    );
};

const Container = styled.div`
    width: 162px;
    margin: 0 auto;
`;

const Button = styled.button`
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 16px;
    border: none;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: #f5f5f5;
    &:hover {
        background-color: #d6d6d6;
    }
`;

const List = styled.ul`
    max-height: 600px;
    overflow-y: scroll;
    width: 178px;
    padding: 0;
    margin: 0;
    border: none;
    list-style: none;
    padding-right: 5px;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
`;

const ListItem = styled.li`
    padding: 12px 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: none;
    color: black;
    background-color: ${(props) => (props.isSelected ? "#c7ecfd" : "#f5f5f5")};
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.isSelected ? "#9dc6da" : "#d6d6d6")};
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

export default SidebarListItem;
