import React, { useEffect, useState, useContext } from "react";
import { createSocket } from "../services/socket";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import { UserSelect, UserChatting } from "./UserSelect";
import { fetchAllUsers, fetchCurrentUser, fetchMessagesThread } from "../services/api";
import { CurrentUserContext } from "../../security/CurrentUserContext";
import styled from "styled-components";
import { Link, useNavigate, useParams, NavLink } from "react-router-dom";
import Slider from "rc-slider";
// import SidebarListItem from "../../components/SidebarListItem.jsx";
import { RadioButton, Success, LoadingStyle } from "../../assets/styledcomponents/StyledComponents.js";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
// console.log("API_BASE_URL", API_BASE_URL);

export default function Chat() {
    const [socket, setSocket] = useState(null);
    const [users, setUsers] = useState([]);
    const [userChattingInfo, setUserChattingInfo] = useState({});
    const [otherUser, setOtherUser] = useState("");
    const [messages, setMessages] = useState([]);
    const [otherUserName, setOtherUserName] = useState("");
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [loadingState, setLoadingState] = useState(true);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [lists, setLists] = useState([]);

    const navigate = useNavigate();
    // const { select_list, unit_id } = useParams();

    const {
        currentUserState,
        actions: { receiveUserInfoFromServer, receiveUserAuthFromServer, removeCurrentUser }
    } = useContext(CurrentUserContext);

    // Socket connection management
    useEffect(() => {
        const socketInstance = createSocket();
        setSocket(socketInstance);

        if (userChattingInfo?._id) {
            socketInstance.emit("joinRoom", userChattingInfo._id);
        }

        return () => {
            if (socketInstance) {
                socketInstance.disconnect();
            }
        };
    }, [userChattingInfo?._id]);

    // Fetching the messages thread
    useEffect(() => {
        if (userChattingInfo?._id && otherUser) {
            fetchMessagesThread(userChattingInfo._id, otherUser)
                .then((data) => setMessages(data.messages))
                .catch((error) => console.error("Failed to fetch messages:", error));
        }
    }, [otherUser, userChattingInfo]);

    useEffect(() => {
        // Listen for incoming messages
        const handleNewMessage = (msg) => {
            // Normalize and update messages state
            const senderId = typeof msg.sender === "object" ? msg.sender._id : msg.sender;
            const recipientId = typeof msg.recipient === "object" ? msg.recipient._id : msg.recipient;

            if (
                (senderId === userChattingInfo?._id && recipientId === otherUser) ||
                (senderId === otherUser && recipientId === userChattingInfo?._id)
            ) {
                setMessages((prevMessages) => [...prevMessages, msg]);
            }
        };

        if (socket) {
            socket.on("newMessage", handleNewMessage);
        }

        return () => {
            if (socket) {
                socket.off("newMessage", handleNewMessage);
            }
        };
    }, [socket, userChattingInfo, otherUser]);

    const handleSend = (content) => {
        if (userChattingInfo?._id && otherUser && content.trim() && socket) {
            socket.emit("sendMessage", {
                sender: userChattingInfo._id,
                recipient: otherUser,
                content
            });
        }
    };

    useEffect(() => {
        const loadUsers = async () => {
            try {
                const data = await fetchAllUsers();
                const usersArray = Array.isArray(data) ? data : data.users;
                setUsers(usersArray || []);
            } catch (error) {
                console.error("Failed to fetch users:", error);
            } finally {
                setLoadingState(false);
            }
        };

        loadUsers();
    }, []);

    useEffect(() => {
        if (currentUserState?._id) {
            fetchCurrentUser(currentUserState)
                .then((data) => setUserChattingInfo(data.user))
                .catch((error) => console.error("Failed to fetch current user:", error));
        }
    }, [currentUserState]);

    useEffect(() => {
        // console.log("Updated userChattingInfo:", userChattingInfo);
    }, [userChattingInfo]);

    // Handle both user id and name update
    const handleOtherUserChange = (selectedId) => {
        setOtherUser(selectedId);
        const selectedUser = users.find((user) => user._id === selectedId);
        setOtherUserName(selectedUser ? `${selectedUser.first_name} ${selectedUser.last_name}` : "");
    };

    const toggleSidebar = () => {
        setSidebarVisible((prev) => !prev);
    };

    const handleListSelect = (listcollection) => {
        navigate("/admin/prospectingmap/" + listcollection);
    };

    const handleMenuToggle = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const handleLogout = () => {
        fetch(`${API_BASE_URL}/api/logout`, {
            credentials: "include",
            method: "POST",
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => {
                if (response.ok) {
                    removeCurrentUser();
                    navigate("/");
                } else {
                    throw new Error("Logout failed");
                }
            })
            .catch(console.error);
    };

    return (
        <>
            <Layout>
                <NavBar>
                    <ToggleButton onClick={toggleSidebar} show={sidebarVisible}>
                        {sidebarVisible ? "Hide" : "Show"}
                    </ToggleButton>

                    <section className="w3l-header-4">
                        <div style={{ display: "flex", justifyContent: "space-between", background: "#b0bcbf" }}>
                            <LogoContainer>
                                <LogoLink to="/">
                                    <LogoImage src="/logo.png" alt="BROWEN Logo" />
                                </LogoLink>
                            </LogoContainer>
                            <MenuButton onClick={handleMenuToggle}>&#9776;</MenuButton>
                            <NavItemsContainer isMenuOpen={isMenuOpen}>
                                <NavItems to="/admin" className="signin">
                                    Admin
                                </NavItems>
                                <NavItems to="/admin/prospectingmap" className="signin">
                                    Prospecting
                                </NavItems>
                                <NavItems to="/admin/instantmessage" className="signin">
                                    Messaging
                                </NavItems>
                                <NavItems onClick={handleLogout} className="signin">
                                    Logout
                                </NavItems>
                            </NavItemsContainer>
                        </div>
                    </section>
                </NavBar>
                <SideBarStyled show={sidebarVisible}>
                    <Sidebar
                        setShowModal={setShowModal}
                        onLoadingState={loadingState}
                        users={users}
                        otherUser={otherUser}
                        handleOtherUserChange={handleOtherUserChange}
                    />
                </SideBarStyled>
                <Content sidebarVisible={sidebarVisible}>
                    <div style={{ padding: "20px" }}>
                        <h1>Messaging: {otherUserName}</h1>
                        <div style={{ display: "flex", gap: "10px", marginBottom: "10px" }}>
                            <UserChatting userChattingInfo={userChattingInfo} label="Current User: " />
                            {/* <UserSelect users={users} selectedUser={otherUser} onChange={handleOtherUserChange} label="Chat With: "  /> */}
                        </div>

                        <MessageList messages={messages} userChattingInfo={userChattingInfo} otherUserName={otherUserName} otherUser={otherUser} />
                        <MessageInput onSend={handleSend} />
                    </div>
                </Content>
            </Layout>
        </>
    );
}

const Sidebar = ({ setShowModal, onLoadingState, users, otherUser, handleOtherUserChange }) => {
    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusDropdownOpen && !event.target.closest("#status-filter-dropdown") && !event.target.closest("#status-filter-button")) {
                setStatusDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [statusDropdownOpen]);

    return (
        <>
            {onLoadingState ? (
                <LoadingStyle style={{ width: "80px", height: "80px", margin: "20px" }} />
            ) : (
                <>
                    <SidebarListItem users={users} handleOtherUserChange={handleOtherUserChange} otherUser={otherUser} />

                    {/* <UserSelect users={users} selectedUser={otherUser} onChange={handleOtherUserChange} label="Chat With: "  /> */}
                </>
            )}
        </>
    );
};

const SidebarListItem = ({ users, handleOtherUserChange, otherUser }) => {
    const [isOpen, setIsOpen] = useState(false);

    // Toggle the visibility of the buildings list
    const toggleList = () => {
        setIsOpen(!isOpen);
    };

    return (
        <Container>
            <ButtonSideBar onClick={toggleList}>Lists {isOpen ? <>&nbsp;(See Less)</> : <>&nbsp;(See more)</>}</ButtonSideBar>
            {isOpen && (
                <>
                    {!users || users.length === 0 ? (
                        <LoadingContainer>
                            <LoadingStyle />
                        </LoadingContainer>
                    ) : (
                        <List>
                            {users.map((user) => {
                                // console.log('Current user:', user);
                                return (
                                    <ListItem key={user._id} onClick={() => handleOtherUserChange(user._id)} isSelected={otherUser === user._id}>
                                        {user.first_name} {user.last_name}
                                    </ListItem>
                                );
                            })}
                        </List>
                    )}
                </>
            )}
        </Container>
    );
};
const ButtonSideBar = styled.button`
    width: 100%;
    font-size: 16px;
    cursor: pointer;
    padding: 12px 16px;
    border: none;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    background-color: #f5f5f5;
    &:hover {
        background-color: #d6d6d6;
    }
`;

const Container = styled.div`
    width: 162px;
    margin: 0 auto;
`;

const List = styled.ul`
    max-height: 600px;
    overflow-y: scroll;
    width: 178px;
    padding: 0;
    margin: 0;
    border: none;
    list-style: none;
    padding-right: 5px;

    ::-webkit-scrollbar {
        width: 6px;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #888;
        border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: #555;
    }

    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    scrollbar-width: thin;
    scrollbar-color: #888 #f1f1f1;
`;

const ListItem = styled.li`
    padding: 12px 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
    border: none;
    color: black;
    background-color: ${(props) => (props.isSelected ? "#c7ecfd" : "#f5f5f5")};
    cursor: pointer;

    &:hover {
        background-color: ${(props) => (props.isSelected ? "#9dc6da" : "#d6d6d6")};
    }
`;

const LoadingContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const DropdownItem = styled.label`
    display: block;
    padding: 8px;
    cursor: pointer;
    color: black;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: grey;
    }
`;

const DropdownMenu = styled.div`
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    width: 120%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const Checkbox = styled.input`
    margin-right: 8px;
`;

const CustomSlider = styled(Slider)`
    .rc-slider-track {
        background-color: #abe2fb !important;
    }
    .rc-slider-handle {
        border-color: #abe2fb !important;
        box-shadow: none !important;
    }
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NavBar = styled.div`
    text-align: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #b0bcbf;
    padding: 10px 20px;
`;

const SideBarStyled = styled.div`
    background-color: #b0bcbf;
    color: white;
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    top: 61px;
    left: 0;
    bottom: 0;
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: hidden; /* Disables horizontal scrolling */
    max-height: calc(100vh - 61px); /* Ensures the sidebar doesn't exceed viewport height */
    transition: transform 0.3s ease-in-out;
    transform: ${({ show }) => (show ? "translateX(0)" : "translateX(-100%)")};
`;

const Content = styled.div`
    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? "200px" : "0")};
    margin-top: 60px;
    padding: 20px;
    overflow-y: scroll;
    height: calc(100vh - 60px);
    transition: margin-left 0.3s ease-in-out;
`;

const NavItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    @media (max-width: 768px) {
        flex-direction: column;
        display: ${({ isMenuOpen }) => (isMenuOpen ? "flex" : "none")};
        background-color: #b0bcbf;
        position: absolute;
        top: 55px;
        right: 0;
        width: 150px;
        z-index: 9999;
    }
`;

const ToggleButton = styled.button`
    position: fixed;
    left: ${({ show }) => (show ? "207px" : "7px")};
    top: 65px;
    z-index: 1010;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: black;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;
    transition: left 0.3s ease-in-out;
    &:hover {
        background-color: #e53935;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
`;

const LogoImage = styled.img`
    width: 200px;
    height: auto;
    @media (max-width: 580px) {
        width: 170px;
    }
    @media (max-width: 480px) {
        width: 125px;
    }
`;

const styles = {
    addButton: {
        width: "100%",
        padding: "10px",
        marginTop: "10px",
        background: "#4CAF50",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontSize: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "5px",
        marginBottom: "10px"
    }
};

const NavItems = styled(NavLink)`
    text-decoration: none;
    transition: 100ms;
    color: white;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    margin: 5px;
    font-size: 20px;
    width: 125px;
    text-align: center;
    border-radius: 3px;
    border: 3px solid transparent;
    &:hover {
        border: 3px solid transparent;
        color: grey;
    }
    &:active {
        color: white;
        border: 3px solid transparent;
        background-color: var(--primary-color);
    }
    .active {
        color: var(--primary-color);
    }
    &:disabled,
    &:hover:disabled {
        background-color: #707070;
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

const Button = styled.button`
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;
    &:hover {
        background-color: #e53935;
    }
`;

const MenuButton = styled.button`
    background: transparent;
    border: none;
    color: white;
    font-size: 30px;
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;
