import { useState, useEffect, useContext, useMemo, useRef, forwardRef } from "react";
import { CurrentUserContext } from "../security/CurrentUserContext";
import styled from "styled-components";
import { Link, useNavigate, useParams, NavLink } from "react-router-dom";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const SmartCondoPlansFloor = ({
    onFloorClick,
    size,
    buildingcollection,
    setAvailableFloors,
    availableFloors,
    setSelectedUnitSmart,
}) => {
    const [drawingMode, setDrawingMode] = useState(false);
    const [drawnPoints, setDrawnPoints] = useState([]);
    const [cursorPoint, setCursorPoint] = useState(null);
    const [showExtraInfoForm, setShowExtraInfoForm] = useState(false);
    const [availableUnits, setAvailableUnits] = useState([]);
    const [formData, setFormData] = useState({ type: "floor", floor: "", unit: "" });

    // Modal dragging state
    const [modalPosition, setModalPosition] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
    const [modalStart, setModalStart] = useState({ x: 0, y: 0 });

    const svgRef = useRef(null);

    const {
        currentUserState,
        actions: { receiveUserInfoFromServer, receiveUserAuthFromServer, removeCurrentUser }
    } = useContext(CurrentUserContext);

    const handleFloorClick = (floorId) => {
        if (onFloorClick) {
            // console.log(`Clicked floor ${floorId}`);
            onFloorClick(floorId);
        } else {
            // console.log("Clicked floor nothing");
        }
    };

    const toggleDrawingMode = () => {
        setDrawingMode((prev) => !prev);
        setDrawnPoints([]);
    };

    const handleSvgClick = (e) => {
        if (!drawingMode) return;
        const svg = svgRef.current;
        if (!svg) return;

        // Convert screen coordinates to SVG coordinates
        const point = svg.createSVGPoint();
        point.x = e.clientX;
        point.y = e.clientY;
        const svgPoint = point.matrixTransform(svg.getScreenCTM().inverse());

        // Apply 90° snapping if shift is held and a previous point exists
        if (e.shiftKey && drawnPoints.length > 0) {
            const lastPoint = drawnPoints[drawnPoints.length - 1];
            const dx = svgPoint.x - lastPoint[0];
            const dy = svgPoint.y - lastPoint[1];
            if (Math.abs(dx) > Math.abs(dy)) {
                svgPoint.y = lastPoint[1];
            } else {
                svgPoint.x = lastPoint[0];
            }
        }
        setDrawnPoints((prev) => [...prev, [svgPoint.x, svgPoint.y]]);
    };

    const handleMouseMove = (e) => {
        if (!drawingMode) return;
        const svg = svgRef.current;
        if (!svg) return;

        // Convert the cursor position to SVG coordinates
        const point = svg.createSVGPoint();
        point.x = e.clientX;
        point.y = e.clientY;
        const svgPoint = point.matrixTransform(svg.getScreenCTM().inverse());

        // Apply snapping on mouse move if shift is held
        if (e.shiftKey && drawnPoints.length > 0) {
            const lastPoint = drawnPoints[drawnPoints.length - 1];
            const dx = svgPoint.x - lastPoint[0];
            const dy = svgPoint.y - lastPoint[1];
            if (Math.abs(dx) > Math.abs(dy)) {
                svgPoint.y = lastPoint[1];
            } else {
                svgPoint.x = lastPoint[0];
            }
        }
        setCursorPoint([svgPoint.x, svgPoint.y]);
    };

    const handleSvgDoubleClick = () => {
        if (drawingMode && drawnPoints.length > 2) {
            // console.log("Polygon drawn with points:", drawnPoints);
            if (formData.type === "unit" && availableFloors.length === 1) {
                setFormData((prev) => ({ ...prev, floor: availableFloors[0].floor }));
            }
            setShowExtraInfoForm(true);
        }
    };

    // Window-level event listeners for dragging the modal
    useEffect(() => {
        const handleMouseMoveWindow = (e) => {
            if (!dragging) return;
            const deltaX = e.clientX - dragStart.x;
            const deltaY = e.clientY - dragStart.y;
            setModalPosition({
                x: modalStart.x + deltaX,
                y: modalStart.y + deltaY,
            });
        };

        const handleMouseUpWindow = () => {
            if (dragging) setDragging(false);
        };

        window.addEventListener("mousemove", handleMouseMoveWindow);
        window.addEventListener("mouseup", handleMouseUpWindow);
        return () => {
            window.removeEventListener("mousemove", handleMouseMoveWindow);
            window.removeEventListener("mouseup", handleMouseUpWindow);
        };
    }, [dragging, dragStart, modalStart]);

    const handleModalMouseDown = (e) => {
        setDragging(true);
        setDragStart({ x: e.clientX, y: e.clientY });
        setModalStart({ x: modalPosition.x, y: modalPosition.y });
    };

    // Save polygon with extra information
    const savePolygon = async (points, extraInfo) => {
        const requestData = {
            buildingcollection,
            points,
            extraInfo,
        };
        try {
            const response = await fetch(`${API_BASE_URL}/api/polygons`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(requestData),
                credentials: "include",
            });
            if (response.ok) console.log("Polygon saved successfully");
            else console.error("Error saving polygon");
        } catch (error) {
            console.error("Error saving polygon:", error);
        }
    };

    const handleConfirm = async () => {
        await savePolygon(drawnPoints, formData);

        const newPolygon = {
            _id: { $oid: new Date().getTime().toString() },
            points: drawnPoints,
            ...formData,
        };

        if (formData.type === "floor") {
            setAvailableFloors((prev) => [...prev, newPolygon]);
        } else if (formData.type === "unit") {
            setAvailableUnits((prev) => [...prev, newPolygon]);
        }

        setShowExtraInfoForm(false);
        setDrawingMode(false);
        setDrawnPoints([]);
        setCursorPoint(null);
        setFormData({ type: "unit", floor: "", unit: "" });
    };

    const handleCancel = () => {
        setShowExtraInfoForm(false);
        setDrawingMode(false);
        setDrawnPoints([]);
        setCursorPoint(null);
        setFormData({ type: "unit", floor: "", unit: "" });
    };

    const drawnPointsString = drawnPoints.map((point) => point.join(",")).join(" ");

    return (
        <Container size={size} id="smartcondoplans-building-image" data-building-id="179">
            <BuildingImage
                src="/assets/images/ErinCondos/Rendering.jpg"
                alt="Building"
            />

            {currentUserState.authenticated && (
                <button
                    onClick={toggleDrawingMode}
                    style={{ position: "absolute", top: 10, left: 10, zIndex: 10 }}
                >
                    {drawingMode ? "Cancel Drawing" : "Enter Drawing Mode"}
                </button>
            )}
            <SvgOverlay
                ref={svgRef}
                viewBox="0 0 2000 1125"
                preserveAspectRatio="xMidYMid meet"
                drawingMode={drawingMode}
                onClick={handleSvgClick}
                onMouseMove={handleMouseMove}
                onDoubleClick={handleSvgDoubleClick}
            >
                {availableFloors.map((floorData) => {
                    const polygonPoints = floorData.points
                        .map((pair) => {
                            const x = Number(pair[0]);
                            const y = Number(pair[1]);
                            return `${x},${y}`;
                        })
                        .join(" ");
                    return (
                        <ClickablePolygon
                            key={floorData._id}
                            points={polygonPoints}
                            data-floor={floorData.floor}
                            data-tooltip={`${floorData.floor} floor`}
                            onClick={() => handleFloorClick(floorData.floor)}
                        />
                    );
                })}
                {drawnPoints.length > 0 && <DrawnPolygon points={drawnPointsString} />}
                {drawingMode && drawnPoints.length > 0 && cursorPoint && (
                    <line
                        x1={drawnPoints[drawnPoints.length - 1][0]}
                        y1={drawnPoints[drawnPoints.length - 1][1]}
                        x2={cursorPoint[0]}
                        y2={cursorPoint[1]}
                        stroke="black"
                        strokeDasharray="5,5"
                        strokeWidth="2"
                    />
                )}
            </SvgOverlay>

            {showExtraInfoForm && (
                <ModalOverlay>
                    <ModalContent style={{ transform: `translate(${modalPosition.x}px, ${modalPosition.y}px)` }}>
                        <ModalHeader onMouseDown={handleModalMouseDown}>
                            <h3>Save Polygon Details</h3>
                        </ModalHeader>
                        <FormField>
                            <Label>Type</Label>
                            <div>
                                <label>
                                    <input
                                        type="radio"
                                        name="type"
                                        value="floor"
                                        checked={formData.type === "floor"}
                                        onChange={(e) =>
                                            setFormData((prev) => ({ ...prev, type: e.target.value }))
                                        }
                                    />{" "}
                                    Floor
                                </label>{" "}
                                
                            </div>
                        </FormField>
                        {formData.type === "floor" && (
                            <FormField>
                                <Label>Floor Number</Label>
                                <Input
                                    type="text"
                                    value={formData.floor}
                                    onChange={(e) =>
                                        setFormData((prev) => ({ ...prev, floor: e.target.value }))
                                    }
                                    placeholder="Enter floor number"
                                />
                            </FormField>
                        )}
                       
                        <ButtonGroup>
                            <Button onClick={handleCancel}>Cancel</Button>
                            <Button onClick={handleConfirm}>Confirm</Button>
                        </ButtonGroup>
                    </ModalContent>
                </ModalOverlay>
            )}
        </Container>
    );
};

const Container = styled.div`
  position: relative;
  width: ${({ size }) => size || "100%"};
  aspect-ratio: 2000 / 1125;
`;

const BuildingImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const SvgOverlay = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: ${({ drawingMode }) => (drawingMode ? "auto" : "none")};
`;

const ClickablePolygon = styled.polygon`
  fill: transparent;
  pointer-events: auto;
  cursor: pointer;
  transition: fill 0.2s ease;

  &:hover {
    fill: rgba(255, 255, 255, 0.2);
  }
`;

const DrawnPolygon = styled.polygon`
  fill: rgba(0, 255, 0, 0.3);
  stroke: green;
  stroke-width: 2;
`;

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 4px;
  width: 300px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const ModalHeader = styled.div`
  cursor: move;
  padding: 10px;
  background: #eee;
  border-bottom: 1px solid #ccc;
`;

const FormField = styled.div`
  margin-bottom: 15px;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
`;

const Input = styled.input`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`;

const Select = styled.select`
  width: 100%;
  padding: 5px;
  box-sizing: border-box;
`;

const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Button = styled.button`
  padding: 8px 12px;
  cursor: pointer;
`;

export default SmartCondoPlansFloor;
