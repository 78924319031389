import './App.css';
import { Routes, Route, useLocation, Navigate} from 'react-router-dom';
import React, { useState, useEffect, useContext } from "react";
import HeaderComponent from './components/HeaderComponent';
import FooterComponent from './components/FooterComponent';
import HomeComponent from './components/HomeComponent';
import PricingComponent from './components/PricingComponent';
import MoveTopComponent from './components/MoveTopComponent';
import LoginComponent from './components/LoginComponent';
import RegisterComponent from './components/RegisterComponent';
import ErrorComponent from './components/ErrorComponent';
import AboutComponent from './components/AboutComponent';
import ServicesComponent from './components/ServicesComponent';
import AdminComponent from './components/Admin';
import ContactComponent from './components/ContactComponenet';
import PropertyByTypeComponent from './components/PropertyByTypeComponent';
import PropertyByLocationComponent from './components/PropertyByLocationComponent';
import PropertyDetailComponent from './components/PropertyDetailComponent';
import { CurrentUserContext } from "./security/CurrentUserContext";
import NewHome from './components/NewHome';
import Door from './components/DoorComp';
import Cookies from 'js-cookie';
import ProspectingMap from './components/ProspectingMap';
import Chat from "./messaging/components/Chat";
import ProtectedRoute from './security/ProtectedRoute';
import AdminLayout from './AdminLayout';
// import SmartCondoPlansMain from './components/SmartPlans';

function App() {

	let location = useLocation();
    
    const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

    const {
        currentUserState,
        actions: {
            receiveUserInfoFromServer,
            receiveUserAuthFromServer,
            removeCurrentUser,
        },
    } = useContext(CurrentUserContext);

	return (
        <>
            {location.pathname !== "/login" && location.pathname !== "/register" && !location.pathname.startsWith("/admin") && <HeaderComponent />}
            <Routes>
                <Route path="/" element={<NewHome />} />
                <Route path="/login" element={<LoginComponent />} />
                <Route path="/register" element={<RegisterComponent />} />
                <Route path="/about" element={<AboutComponent />} />
                <Route path="/services" element={<ServicesComponent />} />
                <Route path="/contact" element={<ContactComponent />} />
                <Route path="/properties" element={<PropertyByTypeComponent />} />
                <Route path="/properties/location/:location" element={<PropertyByLocationComponent />} />
                <Route path="/properties/:propid/*" element={<PropertyDetailComponent />} />
                {/* <Route path="/properties/:propid" element={<PropertyDetailComponent />} />
                <Route path="/properties/:propid" element={<PropertyDetailComponent />} />
                <Route path="/properties/:propid/:floorid" element={<PropertyDetailComponent />} />
                <Route path="/properties/:propid/:floorid/:unitid" element={<PropertyDetailComponent />} /> */}

                {/* Nested admin routes */}
                <Route
                    path="/admin"
                    element={
                        <ProtectedRoute>
                            <AdminLayout />
                        </ProtectedRoute>
                    }
                >
                    <Route path=":select_building?" element={<AdminComponent />} />
                    <Route path="door" element={<Door />} />
                    <Route path="prospectingmap/:select_list?/:unit_id?" element={<ProspectingMap />} />
                    <Route path="instantmessage" element={<Chat />} />
                </Route>
                {/* <Route
                    path="/admin"
                    element={
                        <ProtectedRoute>
                            <AdminLayout />
                        </ProtectedRoute>
                    }
                >
                    <Route index element={<AdminComponent />} />
                    <Route path=":select_building" element={<AdminComponent />} />
                    <Route path="door" element={<Door />} />
                    <Route path="prospectingmap" element={<ProspectingMap />} />
                    <Route path="prospectingmap/:select_list" element={<ProspectingMap />} />
                    <Route path="prospectingmap/:select_list/:unit_id" element={<ProspectingMap />} />
                    <Route path="instantmessage" element={<Chat />} />
                </Route> */}

                <Route path="/*" element={<ErrorComponent />} />
            </Routes>
            {location.pathname !== "/login" && location.pathname !== "/register" && !location.pathname.startsWith("/admin") && <FooterComponent />}
            <MoveTopComponent />
        </>
    );
}

export default App;
