import React from "react";

export function UserSelect({ users = [], selectedUser, onChange, label,  }) {


	// console.log("selectedUser",selectedUser)

	return (
		<div>
			<label>{label}</label>
			
				<select
					value={selectedUser || ""}
					onChange={(e) => onChange(e.target.value)}
				
				>
					<option value="">Select a user</option>
					{users.map((user) => (
						<option key={user._id} value={user._id}>
							{user.first_name} {user.last_name}
						</option>
					))}
				</select>
			
		</div>
	);
}

export function UserChatting({ userChattingInfo, label,  }) {
	

	if (!userChattingInfo) {
		return <div><label>{label}</label><p>No user selected.</p></div>;
	}

	return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <label>{label}</label>
			<span>&nbsp;</span>
            <p>
                {userChattingInfo.first_name} {userChattingInfo.last_name}
            </p>
            {/* <p>Email: {userChattingInfo.email}</p>
  <p>Phone: {userChattingInfo.phone}</p> */}
        </div>
    );
}
