import { useEffect, useState } from 'react';
import { Link, useSearchParams,useParams } from 'react-router-dom';
import useScript from '../hooks/useScript';

import villaImage from '../assets/images/property1.jpg';
import apartmentImage from '../assets/images/property2.jpg';
import houseImage from '../assets/images/property3.jpg';
import condoImage from '../assets/images/property4.jpg';
import townhouseImage from '../assets/images/property5.jpg';
import { getBuildings } from '../api/RentalApiService';

export default function PropertyByTypeComponent() {

    const [searchParams] = useSearchParams();
    const [typeString, setTypeString] = useState();
    const [loadingState, setLoadingState] = useState(true);
    const [buildings, setBuildings] = useState([]);

    useEffect(() => {
        if (searchParams.has("type")) {
            setTypeString(searchParams.get("type"));
        }
    }, [searchParams]);

    useEffect(() => {
        getBuildings("","")
            .then(response => { 
                setBuildings(response.data.data); 
                setLoadingState(false);
            })
            .catch(error => console.log(error));
    }, []);

    function getPropertyImage(type) {
        switch(type) {
            case "rent":
                return apartmentImage;
            case "sale":
                return condoImage;
            case "house":
                return houseImage;
            case "commercial":
                return townhouseImage;
            default:
                return villaImage;
        }
    }

    return (
        <>
            <section className="w3l-breadcrumns">
                <div className="wrapper">
                    <ul>    
                        <li><Link to="/">Home</Link> <span className="fa fa-angle-double-right"></span></li>
                        <li>Properties</li>
                    </ul>
                </div>
            </section>

            <div className="w3l-products-4">
                <div id="products4-block" className="text-center">
                    <h3 className="heading-title">All Properties</h3>
                    <div className="wrapper">
                        {/* Radio Buttons for Tabs */}
                        <input id="tab1" type="radio" name="tabs" defaultChecked={!typeString || typeString === "all"} />
                        <label className="tabtle" htmlFor="tab1">All properties</label>
                        
                        <input id="tab2" type="radio" name="tabs" defaultChecked={typeString === "rent"} />
                        <label className="tabtle" htmlFor="tab2">Apartments</label>

                        <input id="tab3" type="radio" name="tabs" defaultChecked={typeString === "sale"} />
                        <label className="tabtle" htmlFor="tab3">Condos</label>

                        <input id="tab4" type="radio" name="tabs" defaultChecked={typeString === "house"} />
                        <label className="tabtle" htmlFor="tab4">Houses</label>

                        <input id="tab5" type="radio" name="tabs" defaultChecked={typeString === "commercial"} />
                        <label className="tabtle" htmlFor="tab5">Commercial</label>

                        {/* Content for All Properties */}
                        <section id="content1" className="tab-content text-left">
                            <div className="d-grid grid-col-3">
                                {!loadingState &&
                                    buildings.map((building, i) => (
                                        <div className="product" key={i}>
                                            <Link to={"/properties/" + building.buildingcollection}>
                                                <img src={getPropertyImage(building.proptype)} className="img-responsive" alt="" />
                                            </Link>
                                            <div className="info-bg">
                                                <h5>
                                                    <Link to={"/properties/" + building.buildingcollection}>
                                                        {building.proptype === "sale" ? "Condo" :
                                                         building.proptype === "rent" ? "Apartment" :
                                                         building.proptype === "commercial" ? "Commercial" :
                                                         building.proptype === "house" ? "House" : "Property"}
                                                    </Link>
                                                </h5>
                                                <span className="price">{building.building_name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </section>

                        {/* Content for Apartments */}
                        <section id="content2" className="tab-content text-left">
                            <div className="d-grid grid-col-3">
                                {!loadingState &&
                                    buildings.filter(building => building.proptype === "rent").map((building, i) => (
                                        <div className="product" key={i}>
                                            <Link to={"/properties/" + building.buildingcollection}>
                                                <img src={getPropertyImage(building.proptype)} className="img-responsive" alt="" />
                                            </Link>
                                            <div className="info-bg">
                                                <h5>
                                                    <Link to={"/properties/" + building.buildingcollection}>Apartment</Link>
                                                </h5>
                                                <span className="price">{building.building_name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </section>

                        {/* Content for Condos */}
                        <section id="content3" className="tab-content text-left">
                            <div className="d-grid grid-col-3">
                                {!loadingState &&
                                    buildings.filter(building => building.proptype === "sale").map((building, i) => (
                                        <div className="product" key={i}>
                                            <Link to={"/properties/" + building.buildingcollection}>
                                                <img src={getPropertyImage(building.proptype)} className="img-responsive" alt="" />
                                            </Link>
                                            <div className="info-bg">
                                                <h5>
                                                    <Link to={"/properties/" + building.buildingcollection}>Condo</Link>
                                                </h5>
                                                <span className="price">{building.building_name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </section>

                        {/* Content for Houses */}
                        <section id="content4" className="tab-content text-left">
                            <div className="d-grid grid-col-3">
                                {!loadingState &&
                                    buildings.filter(building => building.proptype === "house").map((building, i) => (
                                        <div className="product" key={i}>
                                            <Link to={"/properties/" + building.buildingcollection}>
                                                <img src={getPropertyImage(building.proptype)} className="img-responsive" alt="" />
                                            </Link>
                                            <div className="info-bg">
                                                <h5>
                                                    <Link to={"/properties/" + building.buildingcollection}>House</Link>
                                                </h5>
                                                <span className="price">{building.building_name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </section>

                        {/* Content for Commercial */}
                        <section id="content5" className="tab-content text-left">
                            <div className="d-grid grid-col-3">
                                {!loadingState &&
                                    buildings.filter(building => building.proptype === "commercial").map((building, i) => (
                                        <div className="product" key={i}>
                                            <Link to={"/properties/" + building.buildingcollection}>
                                                <img src={getPropertyImage(building.proptype)} className="img-responsive" alt="" />
                                            </Link>
                                            <div className="info-bg">
                                                <h5>
                                                    <Link to={"/properties/" + building.buildingcollection}>Commercial</Link>
                                                </h5>
                                                <span className="price">{building.building_name}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </section>

                    </div>
                </div>
            </div>
            
            {/* Include Scripts */}
            { useScript(['/assets/library/jquery-3.6.3.min.js']) }
            { useScript('/assets/library/owl.carousel.min.js') }
            { useScript('/assets/js/homepage.js') }
        </>
    );
}
