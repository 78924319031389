import { useState, useEffect, useContext, useMemo, useRef, forwardRef } from "react";
import { CurrentUserContext } from "../security/CurrentUserContext";
import { Link, useNavigate, useParams, NavLink } from "react-router-dom";
import styled from "styled-components";
import { RadioButton, Success, LoadingStyle } from "../assets/styledcomponents/StyledComponents.js";
import ModalComponentMap from "./ModalComponentMap";
import L from "leaflet";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import { getBuildings } from "../api/RentalApiService";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import SidebarListItem from "./SidebarListItem.jsx";
import MarkerClusterGroup from "react-leaflet-cluster";
import DateInput from "../assets/styledcomponents/DateInput.js";

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow
});

const defaultIcon = new L.Icon.Default();

const redIcon = new L.Icon({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: "marker-icon-red"
});

const greenIcon = new L.Icon({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: "marker-icon-green"
});

const yellowIcon = new L.Icon({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
    className: "marker-icon-yellow"
});

// Helper for formatting numbers
const formatNumber = (value) => {
    const num = Number(value);
    return !isNaN(num) ? num.toLocaleString("en-US", { maximumFractionDigits: 0 }) : value;
};

// Shared PATCH helper to update prospecting list details
const patchProspectingList = (propertyId, updates, propertyColl) => {
    return fetch(`${API_BASE_URL}/api/edit-prospecting-list`, {
        credentials: "include",
        method: "PATCH",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ propertyId, updates, propertyColl })
    }).then((response) => {
        if (!response.ok) throw new Error(response.statusText + " - Status: " + response.status);
        return response.json();
    });
};

export default function ProspectingMap() {
    const {
        currentUserState,
        actions: { receiveUserInfoFromServer, receiveUserAuthFromServer, removeCurrentUser }
    } = useContext(CurrentUserContext);
    const navigate = useNavigate();
    const { select_list, unit_id } = useParams();

    // State variables
    const [tableWidth, setTableWidth] = useState(50);
    const [isResizing, setIsResizing] = useState(false);
    const [filters, setFilters] = useState(null);
    const [selectedFilters, setSelectedFilters] = useState(null);
    const [selectedStatuses, setSelectedStatuses] = useState([]);
    const [loadingState, setLoadingState] = useState(true);
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [units, setUnits] = useState([]);
    const [origUnits, setOrigUnits] = useState([]);
    const [selectedList, setSelectedList] = useState(null);
    const [lists, setLists] = useState([]);
    const [postSuccess, setPostSuccess] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [editingRowId, setEditingRowId] = useState(null);
    const [unitStatuses, setUnitStatuses] = useState({});
    const [unitNotes, setUnitNotes] = useState({});
    const [isSatelliteView, setIsSatelliteView] = useState(false);
    const [unitsKey, setUnitsKey] = useState(0);
    const [selectedUnitId, setSelectedUnitId] = useState(null);
    const mapRef = useRef(null);
    const [pinnedUnitId, setPinnedUnitId] = useState(null);
    const markersRef = useRef({});
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [selectedDateFilter, setSelectedDateFilter] = useState(null);
    const [viewMode, setViewMode] = useState(0);
    const [superusersList, setSuperusersList] = useState([]);

    // Fetch superusers from the backend once when component mounts
    useEffect(() => {
        fetch(`${API_BASE_URL}/api/getpeople-prosp`, {
            credentials: "include",
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Unauthorized");
                }
            })
            .then((response_j) => {
                setSuperusersList(response_j.data);
                // setLoadingStatePeople(false)
            })
            .catch((err) => console.log(err));
    }, []);

    // get current user info
    useEffect(() => {
        fetch(`${API_BASE_URL}/api/validateauth`, {
            credentials: "include",
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Unauthorized");
                }
            })
            .then((response_j) => {
                receiveUserInfoFromServer(response_j.data);
                receiveUserAuthFromServer({ authenticated: true });
            })
            .catch((err) => console.log(err));
    }, []);

    // Update tableWidth when viewMode changes
    useEffect(() => {
        if (viewMode === 0) {
            setTableWidth(50);
        } else if (viewMode === 1) {
            setTableWidth(0);
        } else if (viewMode === 2) {
            setTableWidth(100);
        }
    }, [viewMode]);

    function ResizeHandler({ dependency }) {
        const map = useMap();
        useEffect(() => {
            // A slight delay might be necessary if container size adjustments are animated.
            setTimeout(() => {
                map.invalidateSize();
                // Optionally, reset the view center if needed:
                // map.setView(map.getCenter());
            }, 100);
        }, [dependency, map]);
        return null;
    }

    // Function to cycle through view modes
    const cycleViewMode = () => {
        setViewMode((prev) => (prev + 1) % 3);
    };

    // Update selected unit id from URL (only one effect now)
    useEffect(() => {
        if (unit_id) {
            setSelectedUnitId(unit_id.toString());
        }
    }, [unit_id]);

    const handleMenuToggle = () => {
        setIsMenuOpen((prev) => !prev);
    };

    const handleMarkerClick = (unitId, collectionName) => {
        setSelectedUnitId(unitId);
        window.history.pushState(null, "", `/admin/prospectingmap/${select_list}/${unitId}`);

        // Only fetch if unit is not already in state
        if (!units.some((unit) => unit._id.toString() === unitId.toString())) {
            fetch(`${API_BASE_URL}/api/get-unit-by-id?unitId=${unitId}&collectionName=${collectionName}`, {
                credentials: "include",
                headers: { "Content-Type": "application/json" }
            })
                .then((response) => {
                    if (!response.ok) throw new Error("Failed to fetch unit");
                    return response.json();
                })
                .then((data) => {
                    const fetchedUnit = data.data;
                    setUnits((prev) => [...prev, fetchedUnit]);
                    setOrigUnits((prev) => [...prev, fetchedUnit]);
                })
                .catch(console.error);
        }
    };

    const handleRowClick = (unitId) => {
        setSelectedUnitId(unitId);
        window.history.pushState(null, "", `/admin/prospectingmap/${select_list}/${unitId}`);
        if (markersRef.current[unitId]?.openPopup) {
            markersRef.current[unitId].openPopup();
        }
    };

    useEffect(() => {
        if (selectedUnitId && markersRef.current[selectedUnitId]?.openPopup) {
            markersRef.current[selectedUnitId].openPopup();
        }
    }, [selectedUnitId, units]);

    const handleSaveNotes = (unitId, propertyColl, newNotes) => {
        setUnitNotes((prev) => ({ ...prev, [unitId]: newNotes }));
        setUnits((prevUnits) => prevUnits.map((unit) => (unit._id.toString() === unitId.toString() ? { ...unit, notes: newNotes } : unit)));
        patchProspectingList(unitId, { notes: newNotes }, propertyColl)
            // .then(() => alert("Notes updated successfully."))
            .catch(console.error);
    };

    const handleSaveStatus = (unitId, propertyColl, updates) => {
        unitId = unitId.toString();
        // Update the state using the new status from updates
        setUnitStatuses((prev) => ({ ...prev, [unitId]: updates.propstatus }));
        // Merge both propstatus and date_created into the unit's data
        setUnits((prevUnits) => prevUnits.map((unit) => (unit._id.toString() === unitId ? { ...unit, ...updates } : unit)));
        // Pass the full updates object to the patch function
        patchProspectingList(unitId, updates, propertyColl).catch(console.error);
    };

    const getIconForStatus = (status) => {
        if (status === "not interested") return redIcon;
        if (status === "interested") return greenIcon;
        if (status === "call back") return yellowIcon;
        return defaultIcon;
    };

    const handleLogout = () => {
        fetch(`${API_BASE_URL}/api/logout`, {
            credentials: "include",
            method: "POST",
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => {
                if (response.ok) {
                    removeCurrentUser();
                    navigate("/");
                } else {
                    throw new Error("Logout failed");
                }
            })
            .catch(console.error);
    };

    const toggleEditing = (event, id) => {
        event.preventDefault();
        setPostSuccess(false);
        if (editingRowId === id) {
            setEditingRowId(null);
        } else {
            setEditingRowId(id);
            setUnits(origUnits);
        }
    };

    const handleListSelect = (listcollection) => {
        navigate("/admin/prospectingmap/" + listcollection);
    };

    // Fetch prospecting lists
    useEffect(() => {
        fetch(`${API_BASE_URL}/api/get-all-prosp-lists`, {
            credentials: "include",
            method: "GET",
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => {
                if (response.ok) return response.json();
                else alert("Cannot get buildings");
            })
            .then((response_j) => {
                setLists(response_j.data);
                setLoadingState(false);
            })
            .catch(console.error);
    }, [postSuccess]);

    // Fetch detailed unit data for the selected list
    useEffect(() => {
        setUnits([]);
        setOrigUnits([]);
        setFilters(null);
        setSelectedFilters(null);
        setUnitStatuses({});
        setUnitNotes({});

        if (select_list) {
            setLoadingState(true);
            fetch(`${API_BASE_URL}/api/get-prospecting-list/${select_list}`, {
                credentials: "include",
                headers: { "Content-Type": "application/json" }
            })
                .then((response) => {
                    if (response.ok) return response.json();
                    else alert("Cannot get units in selected list");
                })
                .then((response_j) => {
                    const unitsData = response_j.data;
                    fetch(`${API_BASE_URL}/api/get-prospecting-list-details-batch`, {
                        method: "POST",
                        credentials: "include",
                        headers: { "Content-Type": "application/json" },
                        body: JSON.stringify({ unitIds: unitsData.map((u) => u._id), collectionName: select_list })
                    })
                        .then((res) => res.json())
                        .then((data) => {
                            const detailedMap = {};
                            data.data.forEach((unitDetail) => {
                                detailedMap[unitDetail._id] = unitDetail;
                            });
                            const mergedUnits = unitsData.map((unit) => (detailedMap[unit._id] ? { ...unit, ...detailedMap[unit._id] } : unit));
                            setUnits(mergedUnits);
                            setOrigUnits(mergedUnits);
                            initializeFiltersAndStatuses(mergedUnits);
                            setUnitsKey((prev) => prev + 1);
                            setLoadingState(false);
                        })
                        .catch(console.error);
                })
                .catch(console.error);
        }
    }, [select_list, postSuccess]);

    const initializeFiltersAndStatuses = (unitsData) => {
        const newFilters = {
            building_size: { min: Infinity, max: -Infinity },
            land_size: { min: Infinity, max: -Infinity },
            building_valuation: { min: Infinity, max: -Infinity },
            year_built: { min: Infinity, max: -Infinity },
            num_non_res_units: { min: Infinity, max: -Infinity }
        };

        unitsData.forEach((unit) => {
            const bs = parseFloat(unit.building_size);
            const ls = parseFloat(unit.land_size);
            const bv = parseFloat(unit.building_valuation);
            const yb = parseFloat(unit.year_built);
            const nnru = parseFloat(unit.num_non_res_units);

            if (!isNaN(bs)) {
                newFilters.building_size.min = Math.min(newFilters.building_size.min, bs);
                newFilters.building_size.max = Math.max(newFilters.building_size.max, bs);
            }
            if (!isNaN(ls)) {
                newFilters.land_size.min = Math.min(newFilters.land_size.min, ls);
                newFilters.land_size.max = Math.max(newFilters.land_size.max, ls);
            }
            if (!isNaN(bv)) {
                newFilters.building_valuation.min = Math.min(newFilters.building_valuation.min, bv);
                newFilters.building_valuation.max = Math.max(newFilters.building_valuation.max, bv);
            }
            if (!isNaN(yb)) {
                newFilters.year_built.min = Math.min(newFilters.year_built.min, yb);
                newFilters.year_built.max = Math.max(newFilters.year_built.max, yb);
            }
            if (!isNaN(nnru)) {
                newFilters.num_non_res_units.min = Math.min(newFilters.num_non_res_units.min, nnru);
                newFilters.num_non_res_units.max = Math.max(newFilters.num_non_res_units.max, nnru);
            }
        });

        setFilters(newFilters);
        setSelectedFilters({ ...newFilters });

        const initialStatuses = {};
        const initialNotes = {};

        unitsData.forEach((unit) => {
            const unitIdStr = unit._id.toString();
            // Get propstatus, converting non-string values and normalizing blank values
            let status = unit.propstatus;
            if (typeof status !== "string") {
                status = status === null || status === undefined ? "" : String(status);
            }
            status = status.trim();
            if (status === "" || status.toLowerCase() === "none") {
                status = "none";
            }
            initialStatuses[unitIdStr] = status.toLowerCase();
            initialNotes[unitIdStr] = unit.notes || "";
        });

        setUnitStatuses(initialStatuses);
        setUnitNotes(initialNotes);
    };

    const handleDeleteUnit = (event, id, buildingCollection) => {
        event.preventDefault();
        fetch(`${API_BASE_URL}/api/delete-unit`, {
            method: "DELETE",
            credentials: "include",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ unitId: id, buildingCollection })
        })
            .then((response) => {
                if (response.ok) return response.json();
                else throw new Error(response.statusText + " - Status: " + response.status);
            })
            .then(() => {
                setEditingRowId(null);
                setPostSuccess(true);
            })
            .catch(console.error);
    };

    const handleSave = (event, id) => {
        event.preventDefault();
        const formData = units.find((unit) => unit._id === id);
        fetch(`${API_BASE_URL}/api/update-unit`, {
            credentials: "include",
            method: "PATCH",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ formData })
        })
            .then((response) => {
                if (response.ok) return response.json();
                else throw new Error(response.statusText + " - Status: " + response.status);
            })
            .then(() => {
                setOrigUnits(units);
                setEditingRowId(null);
                setPostSuccess(true);
            })
            .catch(console.error);
    };

    const handleCancel = (event) => {
        event.preventDefault();
        setUnits(origUnits);
        setEditingRowId(null);
    };

    const toggleSidebar = () => {
        setSidebarVisible((prev) => !prev);
    };

    const handleAddList = (submitBuilding, list_name) => {
        setPostSuccess(false);
        const requestData = { excelData: submitBuilding, list_name };
        fetch(`${API_BASE_URL}/api/createlist-excel`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(requestData),
            credentials: "include"
        })
            .then((response) => {
                if (response.ok) return response.json();
                else alert(response.message);
            })
            .then(() => {
                setShowModal(false);
                setPostSuccess(true);
                setSelectedList(null);
                navigate("/admin/prospectingmap");
            })
            .catch(console.error);
    };

    const updateUnitData = (id, updates) => {
        setUnits((prevUnits) => prevUnits.map((unit) => (unit._id.toString() === id.toString() ? { ...unit, ...updates } : unit)));
    };

    function FitBounds({ units }) {
        const map = useMap();
        useEffect(() => {
            if (units.length === 0) return;
            if (selectedUnitId) {
                const selectedUnit = units.find((u) => u._id.toString() === selectedUnitId.toString());
                if (selectedUnit) {
                    map.setView(L.latLng(selectedUnit.latitude, selectedUnit.longitude), 18);
                }
            } else {
                const bounds = L.latLngBounds(units.map((unit) => [unit.latitude, unit.longitude]));
                map.fitBounds(bounds);
            }
        }, [units, map, selectedUnitId]);
        return null;
    }

    const handleMouseDown = () => {
        setIsResizing(true);
    };

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (!isResizing) return;
            const containerRect = mapRef.current.getBoundingClientRect();
            let newWidth = ((e.clientX - containerRect.left) / containerRect.width) * 100;
            newWidth = Math.max(20, Math.min(80, newWidth));
            setTableWidth(newWidth);
        };
        const handleMouseUp = () => {
            if (isResizing) setIsResizing(false);
        };
        window.addEventListener("mousemove", handleMouseMove);
        window.addEventListener("mouseup", handleMouseUp);
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
            window.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isResizing]);

    const filteredUnits = useMemo(() => {
        return units.filter((unit) => {
            const bs = parseFloat(unit.building_size);
            const ls = parseFloat(unit.land_size);
            const bv = parseFloat(unit.building_valuation);
            const yb = parseFloat(unit.year_built);
            const nnru = parseFloat(unit.num_non_res_units);
            const bsFilter = !isNaN(bs) && bs >= selectedFilters.building_size.min && bs <= selectedFilters.building_size.max;
            const lsFilter = !isNaN(ls) && ls >= selectedFilters.land_size.min && ls <= selectedFilters.land_size.max;
            const bvFilter = !isNaN(bv) && bv >= selectedFilters.building_valuation.min && bv <= selectedFilters.building_valuation.max;
            const ybFilter = !isNaN(yb) && yb >= selectedFilters.year_built.min && yb <= selectedFilters.year_built.max;
            const nnruFilter = !isNaN(nnru) && nnru >= selectedFilters.num_non_res_units.min && nnru <= selectedFilters.num_non_res_units.max;
            const status = unitStatuses[unit._id]?.toLowerCase() || "none";
            const statusFilter = selectedStatuses.length === 0 || selectedStatuses.includes(status);

            // Date Filter: Only include if unit.date_created is on or before the chosen date.
            let dateFilterPassed = true;
            if (selectedDateFilter) {
                const chosenDate = new Date(selectedDateFilter);
                const unitDate = new Date(unit.date_created);
                dateFilterPassed = unitDate <= chosenDate;
            }

            return bsFilter && lsFilter && bvFilter && ybFilter && nnruFilter && statusFilter && dateFilterPassed;
        });
    }, [units, selectedFilters, selectedStatuses, unitStatuses, selectedDateFilter]);

    return (
        <>
            <Layout>
                <NavBar>
                    <ToggleButton onClick={toggleSidebar} show={sidebarVisible}>
                        {sidebarVisible ? "Hide" : "Show"}
                    </ToggleButton>
                    <ToggleMapViewButton onClick={() => setIsSatelliteView((prev) => !prev)} show={sidebarVisible}>
                        {isSatelliteView ? "Map (Show Base)" : "Map (Show Satellite)"}
                    </ToggleMapViewButton>
                    {/* New ToggleViewModeButton placed below the satellite toggle */}
                    <ToggleViewModeButton onClick={cycleViewMode} show={sidebarVisible}>
                        {viewMode === 0 ? "Split View" : viewMode === 1 ? "Map Only" : "Table Only"}
                    </ToggleViewModeButton>
                    <section className="w3l-header-4">
                        <div style={{ display: "flex", justifyContent: "space-between", background: "#b0bcbf" }}>
                            <LogoContainer>
                                <LogoLink to="/">
                                    <LogoImage src="/logo.png" alt="BROWEN Logo" />
                                </LogoLink>
                            </LogoContainer>
                            <MenuButton onClick={handleMenuToggle}>&#9776;</MenuButton>
                            <NavItemsContainer isMenuOpen={isMenuOpen}>
                                <NavItems to="/admin" className="signin">
                                    Admin
                                </NavItems>
                                <NavItems to="/admin/prospectingmap" className="signin">
                                    Prospecting
                                </NavItems>
                                <NavItems to="/admin/instantmessage" className="signin">
                                    Messaging
                                </NavItems>
                                <NavItems onClick={handleLogout} className="signin">
                                    Logout
                                </NavItems>
                            </NavItemsContainer>
                        </div>
                    </section>
                </NavBar>
                <SideBarStyled show={sidebarVisible}>
                    <Sidebar
                        buildings={lists}
                        onBuildingSelect={handleListSelect}
                        setShowModal={setShowModal}
                        onLoadingState={loadingState}
                        filters={filters}
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        selectedStatuses={selectedStatuses}
                        setSelectedStatuses={setSelectedStatuses}
                        selectedList={select_list}
                        // New props for date filtering:
                        selectedDateFilter={selectedDateFilter}
                        setSelectedDateFilter={setSelectedDateFilter}
                    />
                </SideBarStyled>
                <Content sidebarVisible={sidebarVisible}>
                    <ResizableContainer ref={mapRef}>
                        <TableViewContainer style={{ width: `${tableWidth}%` }}>
                            <ListView
                                units={filteredUnits}
                                select_list={select_list}
                                selectedUnitId={selectedUnitId}
                                onRowClick={handleRowClick}
                                onUpdate={updateUnitData}
                                superusersList={superusersList}
                            />
                        </TableViewContainer>
                        <Divider onMouseDown={handleMouseDown} />
                        <MapViewContainer style={{ width: `${100 - tableWidth}%` }}>
                            <MapWrapper key={unitsKey}>
                                <MapContainer key={unitsKey} maxZoom={18} scrollWheelZoom zoomControl attributionControl={false}>
                                    {isSatelliteView ? (
                                        <TileLayer
                                            attribution="Tiles &copy; Esri ..."
                                            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
                                        />
                                    ) : (
                                        <TileLayer
                                            attribution='&copy; <a href="https://carto.com/">CARTO</a>'
                                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                        />
                                    )}
                                    {/* Add the resize handler component, using viewMode as a dependency */}
                                    <ResizeHandler dependency={viewMode} />
                                    <FitBounds units={filteredUnits} />
                                    <MarkerClusterGroup
                                        chunkedLoading
                                        maxClusterRadius={30}
                                        disableClusteringAtZoom={19}
                                        spiderfyOnMaxZoom
                                        spiderfyDistanceMultiplier={1.5}
                                        showCoverageOnHover={false}
                                    >
                                        {filteredUnits.map((unit) => {
                                            const unitIdStr = unit._id.toString();
                                            const status = unitStatuses[unit._id]?.toLowerCase() || "";
                                            const icon = getIconForStatus(status);
                                            return (
                                                <Marker
                                                    key={unitIdStr}
                                                    position={[unit.latitude, unit.longitude]}
                                                    icon={icon}
                                                    ref={(ref) => {
                                                        markersRef.current[unitIdStr] = ref;
                                                    }}
                                                    eventHandlers={{
                                                        click: () => handleMarkerClick(unitIdStr, unit.listcollection)
                                                    }}
                                                >
                                                    <Popup minWidth={300}>
                                                        <PopupContent
                                                            unit={unit}
                                                            unitId={unitIdStr}
                                                            collectionName={unit.listcollection}
                                                            status={status}
                                                            notes={unitNotes[unitIdStr] || ""}
                                                            onSaveNotes={(newNotes) => handleSaveNotes(unitIdStr, unit.listcollection, newNotes)}
                                                            onSaveStatus={(newStatus) => handleSaveStatus(unitIdStr, unit.listcollection, newStatus)}
                                                            superusersList={superusersList}
                                                        />
                                                    </Popup>
                                                </Marker>
                                            );
                                        })}
                                    </MarkerClusterGroup>
                                </MapContainer>
                                <ModalComponentMap
                                    isOpen={showModal}
                                    onSubmit={handleAddList}
                                    setShowModal={setShowModal}
                                    setPostSuccess={setPostSuccess}
                                    setSelectedBuilding={setSelectedList}
                                />
                            </MapWrapper>
                        </MapViewContainer>
                    </ResizableContainer>
                </Content>
            </Layout>
        </>
    );
}

const PopupContent = ({ unit, unitId, collectionName, status, notes, onSaveNotes, onSaveStatus, superusersList }) => {
    const [activeTab, setActiveTab] = useState("property info");
    const [localNotes, setLocalNotes] = useState(notes);
    const [localStatus, setLocalStatus] = useState(status);
    const [localDate, setLocalDate] = useState(unit?.date_created || "");
    const [fullUnitData, setFullUnitData] = useState(unit || null);
    const [localSelectedUser, setLocalSelectedUser] = useState(unit?.person || "");

    // When the unit prop changes (or popup reopens), reset local values
    useEffect(() => {
        if (unit) {
            setFullUnitData(unit);
            setLocalNotes(unit.notes || "");
            setLocalStatus(unit.propstatus ? unit.propstatus.toLowerCase() : "");
            setLocalDate(unit.date_created || "");
            setLocalSelectedUser(unit.person || "");
        }
    }, [unit]);

    // Helper to format numbers
    const formatNumber = (value) => {
        const num = Number(value);
        return !isNaN(num) ? num.toLocaleString("en-US", { maximumFractionDigits: 0 }) : value;
    };

    // Handler for cancelling alert changes (revert to original values)
    const handleAlertCancel = () => {
        setLocalStatus(fullUnitData.propstatus ? fullUnitData.propstatus.toLowerCase() : "");
        setLocalDate(fullUnitData.date_created || "");
        setLocalSelectedUser(unit.person)
    };

    if (!fullUnitData) {
        return <LoadingStyle style={{ width: "80px", height: "80px", margin: "20px" }} />;
    }

    const BoldLabel = ({ label, value }) => (
        <>
            <span style={{ fontWeight: "bold" }}>{label}:</span> {value}
            <br />
        </>
    );

    return (
        <div>
            <TabHeader>
                <TabButton onClick={() => setActiveTab("property info")} active={activeTab === "property info"}>
                    Property Info
                </TabButton>
                <TabButton onClick={() => setActiveTab("owner info")} active={activeTab === "owner info"}>
                    Owner Info
                </TabButton>
                <TabButton onClick={() => setActiveTab("notes")} active={activeTab === "notes"}>
                    Notes
                </TabButton>
                <TabButton onClick={() => setActiveTab("alert")} active={activeTab === "alert"}>
                    Alert
                </TabButton>
            </TabHeader>
            <TabContent>
                {activeTab === "property info" && (
                    <div>
                        <BoldLabel label="Property Address" value={fullUnitData.property_address} />
                        <BoldLabel label="Lot Number" value={fullUnitData.lot_number} />
                        <BoldLabel label="Building Size (m2)" value={formatNumber(fullUnitData.building_size)} />
                        <BoldLabel label="Building Valuation" value={formatNumber(fullUnitData.building_valuation)} />
                        <BoldLabel label="Land Size (m2)" value={formatNumber(fullUnitData.land_size)} />
                        <BoldLabel label="Property Use" value={fullUnitData.property_use} />
                        <BoldLabel label="Year Built" value={fullUnitData.year_built} />
                        <BoldLabel label="Number of Units" value={formatNumber(fullUnitData.num_non_res_units)} />
                    </div>
                )}
                {activeTab === "owner info" && (
                    <div>
                        <BoldLabel label="Owner Eval" value={fullUnitData.owner_eval} />
                        <BoldLabel label="Owner Address" value={fullUnitData.owner_address} />
                        <BoldLabel label="Cidreq Owner 1" value={fullUnitData.cidreq_owner1} />
                        <BoldLabel label="Phone Number 1" value={fullUnitData.phone_number1} />
                        <BoldLabel label="Cidreq Address 1" value={fullUnitData.cidreq_address1} />
                        <BoldLabel label="Cidreq Owner 2" value={fullUnitData.cidreq_owner2} />
                        <BoldLabel label="Phone Number 2" value={fullUnitData.phone_number2} />
                        <BoldLabel label="Cidreq Address 2" value={fullUnitData.cidreq_address2} />
                    </div>
                )}
                {activeTab === "notes" && (
                    <div>
                        <TextArea value={localNotes} onChange={(e) => setLocalNotes(e.target.value)} placeholder="Add notes here" />
                        <SaveButton onClick={() => onSaveNotes(localNotes)}>Save</SaveButton>
                    </div>
                )}
                {activeTab === "alert" && (
                    <div>
                        <RadioButton
                            label="Not Interested"
                            value="not interested"
                            checked={localStatus === "not interested"}
                            onChange={() => setLocalStatus("not interested")}
                        />
                        <RadioButton
                            label="Interested"
                            value="interested"
                            checked={localStatus === "interested"}
                            onChange={() => setLocalStatus("interested")}
                        />
                        <RadioButton
                            label="Call Back"
                            value="call back"
                            checked={localStatus === "call back"}
                            onChange={() => setLocalStatus("call back")}
                        />
                        <RadioButton label="None" value="none" checked={localStatus === "none"} onChange={() => setLocalStatus("none")} />

                        <select style={{ width: "120px" }} value={localSelectedUser} onChange={(e) => setLocalSelectedUser(e.target.value)}>
                            <option value="">Select Person</option>
                            {(superusersList || []).map((user) => (
                                <option key={user._id} value={user._id}>
                                    {user.first_name} {user.last_name}
                                </option>
                            ))}
                        </select>

                        <div style={{ display: "flex", alignItems: "center", gap: "10px", marginTop: "10px" }}>
                            {/* Wrap DateInput so clicks don’t bubble to the map */}
                            <div onClick={(e) => e.stopPropagation()}>
                                <DateInput
                                    selectedDate={localDate}
                                    handleInputChangeEdit={setLocalDate}
                                    itemId={unitId}
                                    field="date_created"
                                    disabled={false}
                                />
                            </div>
                            {/* When saving, send both updated status and date */}
                            <SaveButton onClick={() => onSaveStatus({ propstatus: localStatus, date_created: localDate, person: localSelectedUser  })}>
                                Save
                            </SaveButton>
                            {/* Cancel button resets unsaved changes */}
                            <button onClick={handleAlertCancel}>Cancel</button>
                        </div>
                    </div>
                )}
            </TabContent>
        </div>
    );
};
// EditableUnitRow Component using forwardRef
const EditableUnitRow = forwardRef(({ unitDetail, select_list, onUpdate, onRowClick, isSelected, index_unit, superusersList}, ref) => {
    const [isEditing, setIsEditing] = useState(false);
    const [localStatus, setLocalStatus] = useState(unitDetail.propstatus || "");
    const [localNotes, setLocalNotes] = useState(unitDetail.notes || "");
    const [localDate, setLocalDate] = useState(unitDetail.date_created || "");
    const [localSelectedUser, setLocalSelectedUser] = useState(unitDetail.person || "");

    useEffect(() => {
        setLocalSelectedUser(unitDetail.person || "");
    }, [unitDetail]);

    // Update local state when unitDetail changes
    useEffect(() => {
        setLocalStatus(unitDetail.propstatus || "");
        setLocalNotes(unitDetail.notes || "");
        setLocalDate(unitDetail.date_created || "");
        setLocalSelectedUser(unitDetail.person || "");
    }, [unitDetail]);

    const handleSave = (e) => {
        e.stopPropagation();
        // Update unit detail with the selected person along with other fields
        const updateData = {
            propstatus: localStatus,
            notes: localNotes,
            date_created: localDate,
            person: localSelectedUser
        };
        patchProspectingList(unitDetail._id, updateData, select_list)
            .then(() => {
                onUpdate(unitDetail._id, updateData);
                setIsEditing(false);
            })
            .catch(console.error);
    };

    const stopPropagation = (e) => e.stopPropagation();

    // useEffect(() => {
    //         console.log("superusersList:", superusersList);
    //     }, [superusersList]);

    return (
        <tr ref={ref} onClick={() => onRowClick(unitDetail._id)} style={isSelected ? { backgroundColor: "#e0f7ff" } : {}}>
            <td>{index_unit}</td>
            <td>{unitDetail.property_address}</td>
            <td>{unitDetail.lot_number}</td>
            <td>{formatNumber(unitDetail.building_size)}</td>
            <td>{formatNumber(unitDetail.building_valuation)}</td>
            <td>{formatNumber(unitDetail.land_size)}</td>
            <td>{unitDetail.property_use}</td>
            <td>{unitDetail.year_built}</td>
            <td>{formatNumber(unitDetail.num_non_res_units)}</td>
            <td>{unitDetail.owner_eval}</td>
            <td>{unitDetail.owner_address}</td>
            <td>
                {isEditing ? (
                    <div onClick={stopPropagation}>
                        <DateInput
                            selectedDate={localDate}
                            handleInputChangeEdit={setLocalDate}
                            itemId={unitDetail._id}
                            field="date_created"
                            disabled={false}
                        />
                    </div>
                ) : (
                    <div style={{ width: "120px" }}>{unitDetail.date_created}</div>
                )}
            </td>
            <td>
                {isEditing ? (
                    <select style={{ width: "120px" }} value={localStatus} onChange={(e) => setLocalStatus(e.target.value)} onClick={stopPropagation}>
                        <option value="not interested">Not Interested</option>
                        <option value="interested">Interested</option>
                        <option value="call back">Call Back</option>
                        <option value="none">None</option>
                    </select>
                ) : (
                    <div style={{ width: "120px" }}>
                        {(() => {
                            let status = typeof unitDetail.propstatus === "string" ? unitDetail.propstatus : String(unitDetail.propstatus);
                            status = status.trim();
                            if (status === "" || status.toLowerCase() === "none") {
                                return "None";
                            }
                            return status
                                .split(" ")
                                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                                .join(" ");
                        })()}
                    </div>
                )}
            </td>
            <td>
                {isEditing ? (
                    <TextArea
                        placeholder="Add notes here"
                        value={localNotes}
                        onChange={(e) => setLocalNotes(e.target.value)}
                        onClick={stopPropagation}
                    />
                ) : (
                    <div style={{ width: "200px" }}>{unitDetail.notes}</div>
                )}
            </td>
            <td>
                {isEditing ? (
                    <select
                        style={{ width: "120px" }}
                        value={localSelectedUser}
                        onChange={(e) => setLocalSelectedUser(e.target.value)}
                        onClick={stopPropagation}
                    >
                        <option value="">Select Person</option>
                        {(superusersList || []).map((user) => (
                            <option key={user._id} value={user._id}>
                                {user.first_name} {user.last_name}
                            </option>
                        ))}
                    </select>
                ) : (
                    <div style={{ width: "120px" }}>
                        {(() => {
                            const user = (superusersList || []).find((user) => user._id === unitDetail.person);
                            return user ? `${user.first_name} ${user.last_name}` : unitDetail.person || "N/A";
                        })()}
                    </div>
                )}
            </td>
            <td onClick={stopPropagation}>
                {isEditing ? (
                    <>
                        <button onClick={handleSave}>Save</button>{" "}
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                setIsEditing(false);
                            }}
                        >
                            Cancel
                        </button>
                    </>
                ) : (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            setIsEditing(true);
                        }}
                    >
                        Edit
                    </button>
                )}
            </td>
        </tr>
    );
});

// ListView Component
const ListView = ({ units, select_list, selectedUnitId, onRowClick, onUpdate, superusersList }) => {
    const containerRef = useRef();
    const rowRefs = useRef({});

    // Sorting state and logic added for selected columns only.
    const [sortConfig, setSortConfig] = useState({ key: null, direction: null });

    const handleSort = (columnKey) => {
        if (sortConfig.key === columnKey) {
            // Toggle: ascending -> descending -> unsorted.
            if (sortConfig.direction === "asc") {
                setSortConfig({ key: columnKey, direction: "desc" });
            } else if (sortConfig.direction === "desc") {
                setSortConfig({ key: null, direction: null });
            } else {
                setSortConfig({ key: columnKey, direction: "asc" });
            }
        } else {
            setSortConfig({ key: columnKey, direction: "asc" });
        }
    };

    // Compute sorted list based on sort configuration.
    const sortedUnits = useMemo(() => {
        if (!sortConfig.key) return units;
        return [...units].sort((a, b) => {
            let aVal = a[sortConfig.key];
            let bVal = b[sortConfig.key];

            // Special handling for dates in "date_created"
            if (sortConfig.key === "date_created") {
                // If the value is blank or falsy, treat it as null.
                const aDate = aVal ? new Date(aVal) : null;
                const bDate = bVal ? new Date(bVal) : null;

                // If both dates are null, they are equal.
                if (!aDate && !bDate) return 0;
                // Decide where to place missing dates.
                // Here, for ascending order, we push null dates to the end.
                if (!aDate) return sortConfig.direction === "asc" ? 1 : -1;
                if (!bDate) return sortConfig.direction === "asc" ? -1 : 1;

                // Both dates exist; compare them.
                if (aDate < bDate) return sortConfig.direction === "asc" ? -1 : 1;
                if (aDate > bDate) return sortConfig.direction === "asc" ? 1 : -1;
                return 0;
            }

            // Attempt numerical comparison
            const aNum = parseFloat(aVal);
            const bNum = parseFloat(bVal);
            if (!isNaN(aNum) && !isNaN(bNum)) {
                aVal = aNum;
                bVal = bNum;
            } else {
                aVal = aVal ? aVal.toString().toLowerCase() : "";
                bVal = bVal ? bVal.toString().toLowerCase() : "";
            }
            if (aVal < bVal) return sortConfig.direction === "asc" ? -1 : 1;
            if (aVal > bVal) return sortConfig.direction === "asc" ? 1 : -1;
            return 0;
        });
    }, [units, sortConfig]);

    useEffect(() => {
        if (selectedUnitId && rowRefs.current[selectedUnitId]) {
            rowRefs.current[selectedUnitId].scrollIntoView({ behavior: "smooth", block: "center" });
        }
    }, [selectedUnitId, units]);

    const getSortIndicator = (columnKey) => {
        if (sortConfig.key === columnKey) {
            return sortConfig.direction === "asc" ? " ⮟" : sortConfig.direction === "desc" ? "⮝" : "";
        }
        return "";
    };

    return (
        <TableContainer ref={containerRef}>
            <StyledTable>
                <thead>
                    <tr>
                        <th>Index</th>
                        <th>Property Address</th>
                        <th>Lot Number</th>
                        <th onClick={() => handleSort("building_size")} style={{ cursor: "pointer" }}>
                            Building Size (m2){getSortIndicator("building_size")}
                        </th>
                        <th onClick={() => handleSort("building_valuation")} style={{ cursor: "pointer" }}>
                            Building Valuation{getSortIndicator("building_valuation")}
                        </th>
                        <th onClick={() => handleSort("land_size")} style={{ cursor: "pointer" }}>
                            Land Size (m2){getSortIndicator("land_size")}
                        </th>
                        <th onClick={() => handleSort("property_use")} style={{ cursor: "pointer" }}>
                            Property Use{getSortIndicator("property_use")}
                        </th>
                        <th onClick={() => handleSort("year_built")} style={{ cursor: "pointer" }}>
                            Year Built{getSortIndicator("year_built")}
                        </th>
                        <th onClick={() => handleSort("num_non_res_units")} style={{ cursor: "pointer" }}>
                            Non-Residential Units{getSortIndicator("num_non_res_units")}
                        </th>
                        <th onClick={() => handleSort("owner_eval")} style={{ cursor: "pointer" }}>
                            Owner Eval{getSortIndicator("owner_eval")}
                        </th>
                        <th onClick={() => handleSort("owner_address")} style={{ cursor: "pointer" }}>
                            Owner Address{getSortIndicator("owner_address")}
                        </th>
                        <th onClick={() => handleSort("date_created")} style={{ cursor: "pointer" }}>
                            Date Contacted{getSortIndicator("date_created")}
                        </th>
                        <th>Status</th>
                        <th>Notes</th>
                        <th>Person</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedUnits.map((unit, index) => {
                        const unitIdStr = unit._id.toString();
                        return (
                            <EditableUnitRow
                                key={unitIdStr}
                                ref={(el) => (rowRefs.current[unitIdStr] = el)}
                                unitDetail={unit}
                                select_list={select_list}
                                onUpdate={onUpdate}
                                onRowClick={onRowClick}
                                isSelected={selectedUnitId === unitIdStr}
                                index_unit={index}
                                superusersList={superusersList}
                            />
                        );
                    })}
                </tbody>
            </StyledTable>
        </TableContainer>
    );
};

// Styled components definitions

const ResizableContainer = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    position: relative;
    top: 15px;
`;

const TableViewContainer = styled.div`
    overflow-y: auto;
    height: 100%;
    > *:first-child {
        padding: 0px;
    }
`;

const MapViewContainer = styled.div`
    overflow-y: auto;
    height: 100%;
`;

const Divider = styled.div`
    width: 5px;
    cursor: col-resize;
    background-color: #ccc;
    &:hover {
        background-color: #aaa;
    }
`;

const MenuButton = styled.button`
    background: transparent;
    border: none;
    color: white;
    font-size: 30px;
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`;

const NavItemsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    @media (max-width: 768px) {
        flex-direction: column;
        display: ${({ isMenuOpen }) => (isMenuOpen ? "flex" : "none")};
        background-color: #b0bcbf;
        position: absolute;
        top: 55px;
        right: 0;
        width: 150px;
        z-index: 9999;
    }
`;

const TableContainer = styled.div`
    height: 100%;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: #fff;
    padding: 10px;
`;

const StyledTable = styled.table`
    width: 100%;
    border-collapse: collapse;
    font-size: 0.9em;

    thead th {
        position: sticky;
        top: 0;
        background: #f2f2f2;
        z-index: 2;
    }

    th,
    td {
        padding: 8px;
        text-align: left;
        border-bottom: 1px solid #ddd;
    }

    th {
        background-color: #f2f2f2;
        font-weight: bold;
    }

    tr:hover {
        background-color: #f9f9f9;
    }
`;

const TabHeader = styled.div`
    display: flex;
    border-bottom: 1px solid #ccc;
`;

const TabButton = styled.button`
    flex: 1;
    padding: 8px;
    background: ${(props) => (props.active ? "#fff" : "#eee")};
    border: none;
    border-bottom: ${(props) => (props.active ? "2px solid blue" : "none")};
    cursor: pointer;
    &:hover {
        background: #ddd;
    }
`;

const TabContent = styled.div`
    padding: 8px;
`;

const TextArea = styled.textarea`
    width: 100%;
    height: 100px;
`;

const SaveButton = styled.button`
    margin-top: 10px;
    padding: 8px 12px;
    background-color: #4caf50;
    color: white;
    border: none;
    cursor: pointer;
    border-radius: 4px;
    &:hover {
        background-color: #45a049;
    }
`;

const Sidebar = ({
    buildings,
    onBuildingSelect,
    setShowModal,
    onLoadingState,
    filters,
    selectedFilters,
    setSelectedFilters,
    selectedStatuses,
    setSelectedStatuses,
    selectedList,
    selectedDateFilter,
    setSelectedDateFilter
}) => {
    const handleStatusCheckboxChange = (event, statusValue) => {
        if (event.target.checked) {
            setSelectedStatuses((prev) => [...prev, statusValue]);
        } else {
            setSelectedStatuses((prev) => prev.filter((status) => status !== statusValue));
        }
    };

    const [statusDropdownOpen, setStatusDropdownOpen] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (statusDropdownOpen && !event.target.closest("#status-filter-dropdown") && !event.target.closest("#status-filter-button")) {
                setStatusDropdownOpen(false);
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
    }, [statusDropdownOpen]);

    // Helper for updating numeric filters from input
    const handleInputChange = (filterKey, bound, value) => {
        const numericValue = Number(value);
        setSelectedFilters((prev) => ({
            ...prev,
            [filterKey]: {
                ...prev[filterKey],
                [bound]: numericValue
            }
        }));
    };

    return (
        <>
            {onLoadingState ? (
                <LoadingStyle style={{ width: "80px", height: "80px", margin: "20px" }} />
            ) : (
                <>
                    <SidebarListItem buildings={buildings} onBuildingSelect={onBuildingSelect} selectedList={selectedList} />
                    <button onClick={() => setShowModal(true)} style={styles.addButton}>
                        Add New List
                    </button>
                </>
            )}
            <div>
                <h3>Filters</h3>
                {filters && (
                    <>
                        {/* Status Filter */}
                        <div style={{ position: "relative", marginBottom: "10px", marginTop: "10px" }}>
                            <StatusFilterButton id="status-filter-button" onClick={() => setStatusDropdownOpen((prev) => !prev)}>
                                Status Filter
                            </StatusFilterButton>
                            {statusDropdownOpen && (
                                <DropdownMenu id="status-filter-dropdown">
                                    {["Interested", "Not Interested", "Call Back", "None"].map((statusOption) => (
                                        <DropdownItem key={statusOption}>
                                            <Checkbox
                                                hidden
                                                type="checkbox"
                                                value={statusOption.toLowerCase()}
                                                checked={selectedStatuses.includes(statusOption.toLowerCase())}
                                                onChange={(e) => handleStatusCheckboxChange(e, statusOption.toLowerCase())}
                                            />
                                            <RadioButton
                                                style={{ transform: "scale(1)" }}
                                                value={statusOption.toLowerCase()}
                                                checked={selectedStatuses.includes(statusOption.toLowerCase())}
                                            />
                                            {statusOption}
                                        </DropdownItem>
                                    ))}
                                </DropdownMenu>
                            )}
                        </div>

                        {/* Building Size Filter */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Building Size: <br />
                                {formatNumber(selectedFilters.building_size.min)} - {formatNumber(selectedFilters.building_size.max)}
                            </label>
                            <CustomSlider
                                range
                                min={filters.building_size.min}
                                max={filters.building_size.max}
                                value={[selectedFilters.building_size.min, selectedFilters.building_size.max]}
                                onChange={(values) =>
                                    setSelectedFilters((prev) => ({
                                        ...prev,
                                        building_size: { min: values[0], max: values[1] }
                                    }))
                                }
                            />
                            <div style={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.building_size.min}
                                    min={filters.building_size.min}
                                    max={selectedFilters.building_size.max}
                                    onChange={(e) => handleInputChange("building_size", "min", e.target.value)}
                                    placeholder="Min"
                                />
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.building_size.max}
                                    min={selectedFilters.building_size.min}
                                    max={filters.building_size.max}
                                    onChange={(e) => handleInputChange("building_size", "max", e.target.value)}
                                    placeholder="Max"
                                />
                            </div>
                        </div>

                        {/* Land Size Filter */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Land Size: <br />
                                {formatNumber(selectedFilters.land_size.min)} - {formatNumber(selectedFilters.land_size.max)}
                            </label>
                            <CustomSlider
                                range
                                min={filters.land_size.min}
                                max={filters.land_size.max}
                                value={[selectedFilters.land_size.min, selectedFilters.land_size.max]}
                                onChange={(values) =>
                                    setSelectedFilters((prev) => ({
                                        ...prev,
                                        land_size: { min: values[0], max: values[1] }
                                    }))
                                }
                            />
                            <div style={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.land_size.min}
                                    min={filters.land_size.min}
                                    max={selectedFilters.land_size.max}
                                    onChange={(e) => handleInputChange("land_size", "min", e.target.value)}
                                    placeholder="Min"
                                />
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.land_size.max}
                                    min={selectedFilters.land_size.min}
                                    max={filters.land_size.max}
                                    onChange={(e) => handleInputChange("land_size", "max", e.target.value)}
                                    placeholder="Max"
                                />
                            </div>
                        </div>

                        {/* Building Valuation Filter */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Building Valuation: <br />
                                {formatNumber(selectedFilters.building_valuation.min)} - {formatNumber(selectedFilters.building_valuation.max)}
                            </label>
                            <CustomSlider
                                range
                                min={filters.building_valuation.min}
                                max={filters.building_valuation.max}
                                value={[selectedFilters.building_valuation.min, selectedFilters.building_valuation.max]}
                                onChange={(values) =>
                                    setSelectedFilters((prev) => ({
                                        ...prev,
                                        building_valuation: { min: values[0], max: values[1] }
                                    }))
                                }
                            />
                            <div style={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.building_valuation.min}
                                    min={filters.building_valuation.min}
                                    max={selectedFilters.building_valuation.max}
                                    onChange={(e) => handleInputChange("building_valuation", "min", e.target.value)}
                                    placeholder="Min"
                                />
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.building_valuation.max}
                                    min={selectedFilters.building_valuation.min}
                                    max={filters.building_valuation.max}
                                    onChange={(e) => handleInputChange("building_valuation", "max", e.target.value)}
                                    placeholder="Max"
                                />
                            </div>
                        </div>

                        {/* Year Built Filter */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Year Built: <br />
                                {formatNumber(selectedFilters.year_built.min)} - {formatNumber(selectedFilters.year_built.max)}
                            </label>
                            <CustomSlider
                                range
                                min={filters.year_built.min}
                                max={filters.year_built.max}
                                value={[selectedFilters.year_built.min, selectedFilters.year_built.max]}
                                onChange={(values) =>
                                    setSelectedFilters((prev) => ({
                                        ...prev,
                                        year_built: { min: values[0], max: values[1] }
                                    }))
                                }
                            />
                            <div style={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.year_built.min}
                                    min={filters.year_built.min}
                                    max={selectedFilters.year_built.max}
                                    onChange={(e) => handleInputChange("year_built", "min", e.target.value)}
                                    placeholder="Min"
                                />
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.year_built.max}
                                    min={selectedFilters.year_built.min}
                                    max={filters.year_built.max}
                                    onChange={(e) => handleInputChange("year_built", "max", e.target.value)}
                                    placeholder="Max"
                                />
                            </div>
                        </div>

                        {/* Non-Residential Units Filter */}
                        <div style={{ marginBottom: "20px" }}>
                            <label>
                                Non-Residential Units: <br />
                                {formatNumber(selectedFilters.num_non_res_units.min)} - {formatNumber(selectedFilters.num_non_res_units.max)}
                            </label>
                            <CustomSlider
                                range
                                min={filters.num_non_res_units.min}
                                max={filters.num_non_res_units.max}
                                value={[selectedFilters.num_non_res_units.min, selectedFilters.num_non_res_units.max]}
                                onChange={(values) =>
                                    setSelectedFilters((prev) => ({
                                        ...prev,
                                        num_non_res_units: { min: values[0], max: values[1] }
                                    }))
                                }
                            />
                            <div style={{ display: "flex", gap: "10px", marginTop: "5px" }}>
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.num_non_res_units.min}
                                    min={filters.num_non_res_units.min}
                                    max={selectedFilters.num_non_res_units.max}
                                    onChange={(e) => handleInputChange("num_non_res_units", "min", e.target.value)}
                                    placeholder="Min"
                                />
                                <StyledNumberInput
                                    type="number"
                                    value={selectedFilters.num_non_res_units.max}
                                    min={selectedFilters.num_non_res_units.min}
                                    max={filters.num_non_res_units.max}
                                    onChange={(e) => handleInputChange("num_non_res_units", "max", e.target.value)}
                                    placeholder="Max"
                                />
                            </div>
                        </div>

                        {/* Date Filter Block */}
                        {/* <div style={{ marginBottom: "20px", position: "relative", zIndex: 1000 }}>
                            <label>Date Contacted (before):</label>
                            <div onClick={(e) => e.stopPropagation()}>
                                <DateInput
                                selectedDate={selectedDateFilter}
                                handleInputChangeEdit={setSelectedDateFilter}
                                itemId="date-filter"
                                field="date_created"
                                disabled={false}
                                />
                            </div>
                            <small>Showing units with a Date Contacted on or before the selected date.</small>
                            </div> */}

                        {/* Reset Filters Button */}
                        <Button
                            style={{ position: "relative", marginBottom: "20px" }}
                            onClick={() => {
                                setSelectedFilters({ ...filters });
                                setSelectedStatuses([]);
                            }}
                        >
                            Reset Filters
                        </Button>
                    </>
                )}
            </div>
        </>
    );
};

const StyledNumberInput = styled.input`
    width: 100%; /* Reduced width from 80px to compensate for removed arrows */
    padding: 5px;
    margin-right: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    color: #333;
    background-color: #f5f5f5;

    /* Remove number input arrows */
    -moz-appearance: textfield;

    /* For Chrome, Safari, Edge, and Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;

const ToggleMapViewButton = styled.button`
    position: fixed;
    left: ${({ show }) => (show ? "284px" : "84px")};
    top: 65px;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: black;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;
    transition: left 0.3s ease-in-out;
    &:hover {
        background-color: #e53935;
    }
`;

// Define a new styled component for the view mode toggle, reusing ToggleMapViewButton styling
const ToggleViewModeButton = styled(ToggleMapViewButton)`
    // top: 105px; /* Adjust the vertical position as needed */
    left: ${({ show }) => (show ? "494px" : "294px")};
`;

const ListItem = styled.li`
    padding: 12px 16px;
    background-color: ${(props) => (props.isSelected ? "#e6f7ff" : "#fff")};
    cursor: pointer;
    &:hover {
        background-color: ${(props) => (props.isSelected ? "#cceeff" : "#f5f5f5")};
    }
    &:not(:last-child) {
        border-bottom: 1px solid #eee;
    }
`;

const CustomSlider = styled(Slider)`
    .rc-slider-track {
        background-color: #abe2fb !important;
    }
    .rc-slider-handle {
        border-color: #abe2fb !important;
        box-shadow: none !important;
    }
`;

const StatusFilterButton = styled.button`
    width: 100%;
    padding: 10px;
    background: #abe2fb;
    color: black;
    border: none;
    cursor: pointer;
    font-size: 16px;
    border-radius: 5px;
    font-weight: bold;
    text-align: left;
    position: relative;
`;

const DropdownMenu = styled.div`
    position: absolute;
    background: white;
    border: 1px solid #ccc;
    width: 120%;
    max-height: 200px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
`;

const DropdownItem = styled.label`
    display: block;
    padding: 8px;
    cursor: pointer;
    color: black;
    justify-content: center;
    align-items: center;
    &:hover {
        background-color: grey;
    }
`;

const Checkbox = styled.input`
    margin-right: 8px;
`;

const Layout = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
`;

const NavBar = styled.div`
    text-align: center;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1000;
    background: #b0bcbf;
    padding: 10px 20px;
`;

const SideBarStyled = styled.div`
    background-color: #b0bcbf;
    color: white;
    width: 200px;
    padding-left: 20px;
    padding-right: 20px;
    position: fixed;
    top: 61px;
    left: 0;
    bottom: 0;
    overflow-y: auto; /* Enables vertical scrolling */
    overflow-x: hidden; /* Disables horizontal scrolling */
    max-height: calc(100vh - 61px); /* Ensures the sidebar doesn't exceed viewport height */
    transition: transform 0.3s ease-in-out;
    transform: ${({ show }) => (show ? "translateX(0)" : "translateX(-100%)")};
`;

const Content = styled.div`
    margin-left: ${({ sidebarVisible }) => (sidebarVisible ? "200px" : "0")};
    margin-top: 60px;
    padding: 20px;
    overflow-y: scroll;
    height: calc(100vh - 60px);
    transition: margin-left 0.3s ease-in-out;
`;

const MapWrapper = styled.div`
    width: 100%;
    height: 100%;
    .leaflet-container {
        width: 100%;
        height: 100%;
    }
`;

const ToggleButton = styled.button`
    position: fixed;
    left: ${({ show }) => (show ? "207px" : "7px")};
    top: 65px;
    z-index: 1010;
    font-size: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: black;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;
    transition: left 0.3s ease-in-out;
    &:hover {
        background-color: #e53935;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    align-items: center;
`;

const LogoLink = styled(Link)`
    display: flex;
    align-items: center;
    text-decoration: none;
`;

const LogoImage = styled.img`
    width: 200px;
    height: auto;
    @media (max-width: 580px) {
        width: 170px;
    }
    @media (max-width: 480px) {
        width: 125px;
    }
`;

const styles = {
    addButton: {
        width: "100%",
        padding: "10px",
        marginTop: "10px",
        background: "#4CAF50",
        color: "white",
        border: "none",
        cursor: "pointer",
        fontSize: "20px",
        paddingLeft: "10px",
        paddingRight: "10px",
        borderRadius: "5px",
        marginBottom: "10px"
    }
};

const NavItems = styled(NavLink)`
    text-decoration: none;
    transition: 100ms;
    color: white;
    align-items: center;
    font-weight: bold;
    justify-content: center;
    margin: 5px;
    font-size: 20px;
    width: 125px;
    text-align: center;
    border-radius: 3px;
    border: 3px solid transparent;
    &:hover {
        border: 3px solid transparent;
        color: grey;
    }
    &:active {
        color: white;
        border: 3px solid transparent;
        background-color: var(--primary-color);
    }
    .active {
        color: var(--primary-color);
    }
    &:disabled,
    &:hover:disabled {
        background-color: #707070;
        opacity: 0.4;
        cursor: not-allowed;
    }
`;

const Button = styled.button`
    font-size: 22px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: #ef5350;
    color: #fff;
    border: none;
    border-radius: 5px;
    margin-bottom: 1px;
    cursor: pointer;
    &:hover {
        background-color: #e53935;
    }
`;

export { ProspectingMap };
