import React, { useState, useContext } from "react";
import { CurrentUserContext } from "../security/CurrentUserContext";
import { Link, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { FaUnlock } from "react-icons/fa6";
import { RiMickeyFill } from "react-icons/ri";

const API_LOCK_URL = process.env.REACT_APP_API_BASE_LOCK_URL;
const LOCK_CLIENT_AUTH_TOKEN = process.env.REACT_APP_LOCK_CLIENT_AUTH_TOKEN;

const DoorComp = () => {
    const navigate = useNavigate();
    const [modalMessage, setModalMessage] = useState(null); // State to manage modal message
    const {
        currentUserState,
    } = useContext(CurrentUserContext);

    const handleBack = () => {
        navigate("/");
    };

    const handleUnlock = () => {
        fetch(`${API_LOCK_URL}/unlock`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${LOCK_CLIENT_AUTH_TOKEN}`
            }
        })
            .then((response) => {
                if (!response.ok) {
                    return response.json().then((error) => {
                        throw new Error(error.message || "Unknown error");
                    });
                }
                return response.json();
            })
            .then((data) => {
                console.log(data);
                setModalMessage(data.message); // Set modal message on success
            })
            .catch((error) => {
                console.error("Error:", error);
                setModalMessage(`An error occurred: ${error.message}`); // Set modal message on error
            });
    };

    if (!currentUserState.authenticated) {
        return (
            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", width: "100vw" }}>
                <div>
                    <p>Please log in. Or contact administrator for Login Credentials.</p>
                    <Link to="/login">Click Here to Login</Link>
                </div>
            </div>
        );
    }

    return (
        <PageWrapper>
            {modalMessage && (
                <Modal>
                    <ModalContent>
                        <p>{modalMessage}</p>
                        <CloseButton onClick={() => setModalMessage(null)}>Close</CloseButton>
                    </ModalContent>
                </Modal>
            )}
            <BackButton onClick={handleBack}>Back</BackButton>
            <UserInfo>
                <UserText>Welcome Home: {currentUserState.first_name || "User Name"}</UserText>
                <SmallText>Username: {currentUserState.email || "Email"}</SmallText>
            </UserInfo>
            <UnlockButton onClick={handleUnlock}>
                <FaUnlock />
                {/* <RiMickeyFill /> */}
            </UnlockButton>
        </PageWrapper>
    );
};

// Styled components
const PageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
    padding: 16px;
`;

const UserInfo = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 16px;
`;

const BackButton = styled.button`
    position: fixed;
    top: 16px; /* Distance from the top */
    left: 16px; /* Distance from the left */
    background-color: #007aff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;

    &:hover {
        background-color: #005bb5;
    }
`;

const UnlockButton = styled.button`
    background-color: #43c97b;
    color: white;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 300px; 
    height: 300px;
    border-radius: 50%;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    font-size: 150px;

    &:hover {
        background-color: #009624;
    }

    svg {
        font-size: 100px;
        color: white; /* Explicit color for the icon */
        -webkit-transform: translateZ(0); /* Fixes rendering issues on Safari */
    }
`;

const Modal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;  /* Added z-index to place modal on top */
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 580px) {
        padding: 20px;
    }
`;

const ModalContent = styled.div`
    background: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
`;

const CloseButton = styled.button`
    background-color: #007aff;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 16px;

    &:hover {
        background-color: #005bb5;
    }
`;

const UserText = styled.div`
    font-size: 22px;
    color: #333;
`;

const SmallText = styled.div`
    font-size: 18px;
    color: #666;
`;

export default DoorComp;
