import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { getBuildings } from "../api/RentalApiService";
import styled from 'styled-components';
import SmartCondoPlansFloor from "./SmartCondoPlansFloor";
import SmartCondoPlansUnit from "./SmartCondoPlansUnit";
import SmartCondoPlansUnitDetail from "./SmartCondoPlansUnitDetail";

// Import React Leaflet components
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';

// Import React Slick components
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Import images
import villaImage from '../assets/images/property1.jpg';
import apartmentImage from '../assets/images/property2.jpg';
import houseImage from '../assets/images/property3.jpg';
import condoImage from '../assets/images/property4.jpg';
import townhouseImage from '../assets/images/property5.jpg';

// Fix for default icon images not loading in Leaflet
import L from 'leaflet';
import markerIcon2x from 'leaflet/dist/images/marker-icon-2x.png';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

L.Icon.Default.mergeOptions({
    iconRetinaUrl: markerIcon2x,
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
});

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export default function PropertyDetailComponent() {

    const navigate = useNavigate();
    const { propid, floorid, unitid } = useParams();

    const [units, setUnits] = useState([]);
    const [loadingState, setLoadingState] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedUnit, setSelectedUnit] = useState(null);
    
    const [selectedUnitSmart, setSelectedUnitSmart] = useState({});
    const [availableFloors, setAvailableFloors] = useState([]);
    const [floorSelected, setFloorSelected] = useState(null);
    
    const [availableUnitsPoly, setAvailableUnitsPoly] = useState([]);

    const [allPolys, setAllPolys] = useState([]);

    useEffect(() => {
        // Fetch units for the property by building ID
        getBuildings("", propid)
            .then(response => {
                if (response.data.data && response.data.data.length > 0) {
                    setUnits(response.data.data);
                    setLoadingState(false);
                } else {
                    navigate("/pageNotFound");
                }
            })
            .catch(error => {
                console.log(error);
                navigate("/pageNotFound");
            });
    }, [propid, navigate]);

    // Fetch unit polygons for the current floor
    useEffect(() => {
        fetch(
            `${API_BASE_URL}/api/getAllbuildingPoly?buildingcollection=${propid}`,
            {
                // credentials: "include",
                headers: { "Content-Type": "application/json" },
            }
        )
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error("Unauthorized");
                }
            })
            .then((response_j) => {
                const tempresponse = response_j.data
                setAllPolys(tempresponse);
                const floorstemp = tempresponse.filter(item => item.type === "floor");
                setAvailableFloors(floorstemp)
            })
            .catch((err) => console.log(err));
    }, [propid]);
    

    if (loadingState || units.length === 0) {
        return <div>Loading...</div>;
    }

    // Assuming all units belong to the same property/building
    const property = units[0];

    function getPropertyImage(type) {
        switch (type) {
            case "rent":
                return apartmentImage;
            case "sale":
                return condoImage;
            case "house":
                return houseImage;
            case "commercial":
                return townhouseImage;
            default:
                return villaImage;
        }
    }

    // Carousel settings
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    };

    // Placeholder images for the carousel
    const carouselImages = [
        getPropertyImage(property.proptype),
        // Add more images as needed
        villaImage,
        apartmentImage,
        houseImage,
    ];

    // Handler for unit click
    const handleUnitClick = (unit) => {
        setSelectedUnit(unit);
        setShowModal(true);
    };

    // Handler to close the modal
    const closeModal = () => {
        setShowModal(false);
        setSelectedUnit(null);
    };

    const onFloorClick = (floor) => {
        // setShowModal(false);
        // setSelectedUnit(null);
        setFloorSelected(floor)
        setSelectedUnitSmart({})
        const unitsOnfloor = allPolys.filter(item => {
            return (item.type === "unit" && item.floor === floor)
        });        
        setAvailableUnitsPoly(unitsOnfloor)

        window.history.pushState(null, "", `/properties/${propid}/${floor}`);
    };

    const onUnitClick = (unitId) => {
        // setShowModal(false);
        // setSelectedUnit(null);
        // setFloorSelected(floor)
        const unitPoly = allPolys.find(item => {
            // console.log("item",item)
            return (item.type === "unit" && item.floor === floorSelected && item._id === unitId)
        });
        // console.log("selectedUnitSmart",unitPoly)
        setSelectedUnitSmart(unitPoly);
        // console.log("propid, floorSelected, unitId", propid, floorSelected, unitId)
        window.history.pushState(null, "", `/properties/${propid}/${floorSelected}/${unitId}`);
    };

    return (
        <>
            <section className="w3l-blog-single">
                <div className="single blog">
                    <div className="wrapper">
                        <SingleLeftContainer className="single-left">
                            {/* Property Details */}
                            <div className="single-left1">
                                <h5 className="card-title no-margin">
                                    {property.proptype === "sale" ? "Condo" :
                                        property.proptype === "rent" ? "Apartment" :
                                            property.proptype === "commercial" ? "Commercial" :
                                                property.proptype === "house" ? "House" : "Property"} for {property.proptype}
                                </h5>
                                <p className="address"><span className="fa fa-map-marker"></span> {property.address}</p>

                                {/* Image Carousel */}
                                <CarouselWrapper>
                                    {/* <Slider {...settings}>
                                        {carouselImages.map((image, index) => (
                                            <div key={index}>
                                                <img src={image} alt={`Slide ${index}`} />
                                            </div>
                                        ))}
                                    </Slider> */}
                                    <h5 className="card-title">Building</h5>
                                    <SmartCondoPlansFloor buildingcollection={propid} onFloorClick={onFloorClick}
                                        availableFloors={availableFloors}
                                        setAvailableFloors={setAvailableFloors}
                                        allPolys={allPolys}
                                        setSelectedUnitSmart={setSelectedUnitSmart}
                                    /> {/*  size="1080px"*/}
                                </CarouselWrapper>
                                {floorSelected && (<CarouselWrapper>
                                    <h5 className="card-title">Floor</h5>
                                    <SmartCondoPlansUnit buildingcollection={propid} onUnitClick={onUnitClick} floorSelected={floorSelected}
                                        setAvailableFloors={setAvailableFloors} availableFloors={availableFloors} availableUnitsPoly={availableUnitsPoly}
                                        setAvailableUnitsPoly={setAvailableUnitsPoly} />
                                </CarouselWrapper>
                                )}

                                {floorSelected && Object.keys(selectedUnitSmart).length > 0  && (<CarouselWrapper>
                                    <h5 className="card-title">Unit</h5>
                                    <SmartCondoPlansUnitDetail buildingcollection={propid} onUnitClick={onUnitClick} floorSelected={floorSelected}
                                        setAvailableFloors={setAvailableFloors} availableFloors={availableFloors} selectedUnitSmart={selectedUnitSmart}/>
                                </CarouselWrapper>
                                )}
                                <h5 className="card-title">Description</h5>
                                <div className="">
                                    <p>{property.description || "No description available."}</p>
                                </div>
                            </div>

                            {/* Units List */}
                            <div className="single-left1">
                                <h5 className="card-title">Available Units</h5>
                                <UnitsTable className="units-table">
                                    <thead>
                                        <tr>
                                            <th>Unit Name</th>
                                            <th>Model Type</th>
                                            <th>Floor Number</th>
                                            <th>Monthly Rent</th>
                                            <th>Square Feet</th>
                                            <th>Bedrooms</th>
                                            <th>Baths</th>
                                            <th>Available From</th>
                                            <th>Availability</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {units.map((unit) => (
                                            <tr key={unit._id} onClick={() => handleUnitClick(unit)} style={{ cursor: 'pointer' }}>
                                                <td data-label="Unit Name">{unit.unit_name}</td>
                                                <td data-label="Model Type">{unit.model_type}</td>
                                                <td data-label="Floor Number">{unit.floor_number}</td>
                                                <td data-label="Monthly Rent">${unit.monthly_rent}</td>
                                                <td data-label="Square Feet">{unit.square_feet}</td>
                                                <td data-label="Bedrooms">{unit.number_bedrooms}</td>
                                                <td data-label="Baths">{unit.number_baths}</td>
                                                <td data-label="Available From">{unit.available_from}</td>
                                                <td data-label="Availability">{unit.availability ? "Available" : "Not Available"}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </UnitsTable>
                            </div>

                            {/* Map Section */}
                            <div className="single-left1">
                                <h5 className="card-title">Map</h5>
                                <MapWrapper>
                                    <MapContainer
                                        center={[property.geometry.lat, property.geometry.lng]}
                                        zoom={13}
                                        scrollWheelZoom={false}
                                    >
                                        {/* Using a prettier tile layer */}
                                        <TileLayer
                                            attribution='&copy; <a href="https://carto.com/">CARTO</a>'
                                            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                        />
                                        <Marker
                                            position={[property.geometry.lat, property.geometry.lng]}
                                        >
                                            <Popup>
                                                {property.building_name}<br />{property.address}
                                            </Popup>
                                        </Marker>
                                    </MapContainer>
                                </MapWrapper>
                            </div>

                            {/* Social Share Section */}
                            <div className="social-share">
                                <h3 className="aside-title">Share this property</h3>
                                <div className="social-icons-section">
                                    <a className="facebook" href="#facebook">
                                        <span className="fa fa-facebook"></span>
                                    </a>
                                    <a className="twitter" href="#twitter">
                                        <span className="fa fa-twitter"></span>
                                    </a>
                                    <a className="instagram" href="#instagram">
                                        <span className="fa fa-instagram"></span>
                                    </a>
                                    <a className="pinterest" href="#pinterest">
                                        <span className="fa fa-pinterest"></span>
                                    </a>
                                </div>
                            </div>

                            {/* Property Details List */}
                            <div className="single-left1">
                                <h5 className="card-title">Details</h5>
                                <ul className="details-list">
                                    <li><strong>Property ID :</strong> {property.buildingcollection}</li>
                                    <li><strong>Property Type :</strong> {property.proptype}</li>
                                    <li><strong>Property Name :</strong> {property.building_name}</li>
                                    <li><strong>Address :</strong> {property.address}</li>
                                    <li><strong>Date Created :</strong> {property.date_created}</li>
                                    {/* Add other details as necessary */}
                                </ul>
                            </div>
                        </SingleLeftContainer>
                    </div>
                </div>
            </section>

            {/* Modal for Unit Details */}
            {showModal && selectedUnit && (
                <ModalOverlay show={showModal} onClick={closeModal}>
                    <ModalContent onClick={(e) => e.stopPropagation()}>
                        <CloseButton onClick={closeModal}>&times;</CloseButton>
                        <h2>Unit Details - {selectedUnit.unit_name}</h2>
                        {/* Display floor plan image if available */}
                        {selectedUnit.floorplan_url ? (
                            <img src={selectedUnit.floorplan_url} alt="Floor Plan" style={{ width: '100%', height: 'auto' }} />
                        ) : (
                            <p>No floor plan available.</p>
                        )}
                        <ul>
                            <li><strong>Model Type:</strong> {selectedUnit.model_type}</li>
                            <li><strong>Floor Number:</strong> {selectedUnit.floor_number}</li>
                            <li><strong>Monthly Rent:</strong> ${selectedUnit.monthly_rent}</li>
                            <li><strong>Square Feet:</strong> {selectedUnit.square_feet}</li>
                            <li><strong>Bedrooms:</strong> {selectedUnit.number_bedrooms}</li>
                            <li><strong>Baths:</strong> {selectedUnit.number_baths}</li>
                            <li><strong>Available From:</strong> {selectedUnit.available_from}</li>
                            <li><strong>Availability:</strong> {selectedUnit.availability ? "Available" : "Not Available"}</li>
                            {/* Add more unit details if necessary */}
                        </ul>
                    </ModalContent>
                </ModalOverlay>
            )}
        </>
    );
}


// Styled component for the container
const SingleLeftContainer = styled.div`
    width: 100%;
`;

// Styled component for the map
const MapWrapper = styled.div`
    width: 100%;
    height: 400px;
    margin-top: 20px;

    .leaflet-container {
        width: 100%;
        height: 100%;
    }
`;

// Styled component for the image carousel
const CarouselWrapper = styled.div`
    margin-top: 20px;

    .slick-slide img {
        display: block;
        width: 100%;
        height: auto;
    }
`;

// Styled component for the modal
const ModalOverlay = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: ${({ show }) => (show ? 'block' : 'none')};
    z-index: 1000;
`;

const ModalContent = styled.div`
    background-color: #fff;
    margin: 5% auto;
    padding: 20px;
    width: 80%;
    max-width: 600px;
    position: relative;
`;

const CloseButton = styled.span`
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
`;

// Styled component for the units table
const UnitsTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  thead {
    display: table-header-group;
    background-color: #f2f2f2;
  }

  tbody tr {
    border-bottom: 1px solid #ddd;
  }

  th, td {
    padding: 8px;
    text-align: left;
    vertical-align: middle;
  }

  /* Responsive styles */
  @media screen and (max-width: 768px) {
    thead {
      display: none;
    }

    tbody tr {
      display: block;
      margin-bottom: 15px;
      border-bottom: 2px solid #ddd;
    }

    tbody tr td {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }

    tbody tr td::before {
      content: attr(data-label);
      flex-basis: 50%;
      font-weight: bold;
      padding-right: 10px;
    }

    tbody tr td:last-child {
      border-bottom: none;
    }
  }
`;
