// frontend/src/components/MessageInput.js
import React, { useState } from "react";
import styled from "styled-components";

export default function MessageInput({ onSend }) {
	const [content, setContent] = useState("");

	const handleSend = () => {
		onSend(content);
		setContent("");
	};

	// Optionally, handle key events if you want to send on Enter (and allow Shift+Enter for newline)
	const handleKeyDown = (e) => {
		if (e.key === "Enter" && !e.shiftKey) {
			e.preventDefault();
			handleSend();
		}
	};

	return (
		<div style={{ marginTop: "10px" }}>
			<StyledTextArea
				// type="text"
				value={content}
				onChange={(e) => setContent(e.target.value)}
				placeholder="Type a message..."
				style={{ width: "100%",height: "100px"  }}
				onKeyDown={handleKeyDown} // Uncomment to enable sending with Enter
			/>
			<button onClick={handleSend}>Send</button>
		</div>
	);
}

const StyledTextArea = styled.textarea`
  width: 100%;
//   min-width: 340px;
  height: 100px;
`;