// frontend/src/services/api.js
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const API_URL = API_BASE_URL + "/api";

export async function fetchAllUsers() {
    const res = await fetch(`${API_URL}/get-all-users`, {
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        method: "get"
    });
    return res.json();
}

export async function fetchCurrentUser(currentUserState) {
    const res = await fetch(`${API_URL}/get-current-user`, {
        method: "POST",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ userId: currentUserState._id.toString() })
    });
    return res.json();
}

export async function sendMessage(sender, recipient, content) {
    const res = await fetch(`${API_URL}/messages`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ sender, recipient, content }),
        credentials: "include"
    });
    return res.json();
}


export async function fetchMessagesThread(user1, user2) {
    const queryParams = new URLSearchParams({ user1, user2 }).toString();
    const res = await fetch(`${API_URL}/messages-thread?${queryParams}`, {
        method: "GET",
        credentials: "include"
    });
    return res.json();
}
