// ProtectedRoute.js
import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import { CurrentUserContext } from "./CurrentUserContext";
import { LoadingStyle } from "../assets/styledcomponents/StyledComponents";

const ProtectedRoute = ({ children }) => {
    const { currentUserState } = useContext(CurrentUserContext);

    if (currentUserState.loading) {
        // Show a loading indicator while authentication is being verified
        return (
            <>
        <div>
                Loading...
            </div>
            <LoadingStyle
            style={{
                width: "80px",
                height: "80px",
                margin: "20px"
            }}
        />
        </>
        );
    }

    if (!currentUserState.authenticated) {
        // Redirect to login if the user is not authenticated
        return <Navigate to="/login" replace />;
    }

    return children;
};

export default ProtectedRoute;
