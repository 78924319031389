import { createContext, useState, useReducer, useEffect } from "react";

export const CurrentUserContext = createContext();

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

const initialState = {
    _id: "",
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    date_created: "",
    password: "",
    deleted: false,
    authenticated: false,
    loading: true, // New property
};

const reducer = (currentUserState, action) => {
    switch (action.type) {
        case "receive-user-info-from-server":
            // console.log("info context", action)
            // console.log(Object.keys(action))
            return {
                ...currentUserState,
                _id: action._id,
                first_name: action.first_name,
                last_name: action.last_name,
                email: action.email,
                phone: action.phone,
                date_created: action.date_created,
                password: action.password,
                deleted: action.deleted
                // authenticated: false  // Maintain authenticated as false until explicitly set
            };
        case "receive-user-auth-from-server":
            return {
                ...currentUserState,
                authenticated: action.authenticated,
                loading: false // Authentication is complete
            };
        case "remove-current-user":
            return {
                // console.log("auth context", action)
                ...initialState,
                loading: false // Even if not authenticated, loading is done
            };
        default:
            throw new Error("Unrecognized action:" + action.type);
    }
};

export const CurrentUserProvider = ({ children }) => {
    const [currentUserState, dispatch] = useReducer(reducer, initialState);

    const receiveUserInfoFromServer = (data) => {
        // console.log("data info",data)
        dispatch({
            type: "receive-user-info-from-server",
            ...data
        });
    };

    const receiveUserAuthFromServer = (data) => {
        // console.log("data auth",data)
        dispatch({
            type: "receive-user-auth-from-server",
            ...data
        });
    };
    const removeCurrentUser = (data) => {
        // console.log("data remove",data)
        dispatch({
            type: "remove-current-user",
            ...data
        });
    };
    // Run authentication check once when the app loads
    useEffect(() => {
        fetch(`${API_BASE_URL}/api/validateauth`, {
            credentials: "include",
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => {
                if (response.ok) return response.json();
                throw new Error("Unauthorized");
            })
            .then((response_j) => {
                receiveUserInfoFromServer(response_j.data);
                receiveUserAuthFromServer({ authenticated: true });
            })
            .catch((err) => {
                console.log("Auth failed:", err);
                removeCurrentUser();
            });
    }, []);

    return (
        <CurrentUserContext.Provider
            value={{
                currentUserState,
                actions: {
                    receiveUserInfoFromServer,
                    receiveUserAuthFromServer,
                    removeCurrentUser
                }
            }}
        >
            {children}
        </CurrentUserContext.Provider>
    );
};
