// frontend/src/services/socket.js
import { io } from 'socket.io-client';

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const createSocket = () => {
    return io(API_BASE_URL, {
        withCredentials: true, // Ensures cookies are sent
    });
};
